/**
 * データ管理用クラス
 */
class DataManager {
  // ---------------------------------------------------------------------------------------------------------
  // --	DataManager
  // ---------------------------------------------------------------------------------------------------------

  /**
   * コンストラクタ
   */
  constructor() {
    this.map = new Map()
  }

  setData(key, val) {
    this.map.set(key, val)
  }

  getData(key) {
    return this.map.get(key)
  }

  deleteData(key) {
    this.map.delete(key)
  }

  clear() {
    this.map.clear()
  }

  // ---------------------------------------------------------------------------------------------------------
}

export default new DataManager()
