/**
 * ユーザーエージェントからデバイス文字列を返す
 * スマホ:sp, その他:pc
 */
export const getDevice = () => {
  const ua = navigator.userAgent
  if (
    ua.indexOf('iPhone') >= 0 ||
    ua.indexOf('iPod') >= 0 ||
    (ua.indexOf('Android') >= 0 && ua.indexOf('Mobile') >= 0)
  ) {
    return 'sp'
  }
  return 'pc'
}

/**
 * Android判定
 */
export const isAndroid = () => {
  const ua = navigator.userAgent
  return ua.indexOf('Android') >= 0
}

/**
 * iOS判定
 */
export const isIOS = () => {
  const ua = navigator.userAgent
  if (
    ua.indexOf('iPhone') >= 0 ||
    ua.indexOf('iPod') >= 0 ||
    ua.indexOf('iPad') >= 0
  ) {
    return true
  }
  return false
}

export const isIE = () => {
  const ua = navigator.userAgent
  return ua.indexOf('Trident') >= 0 || ua.indexOf('MSIE') >= 0
}
