import * as PIXI from 'pixi.js-legacy'
import { TweenMax } from 'gsap'
import emitter, { EVENT_TYPE } from '../../events'
import AssetsManager from '../data/AssetsManager'

/**
 * AvatarViewクラス
 */
class AvatarView extends PIXI.Container {
  // ---------------------------------------------------------------------------------------------------------
  // --	AvatarView
  // ---------------------------------------------------------------------------------------------------------

  /**
   * コンストラクタ
   */
  constructor(images) {
    super()

    //まばたき用のタイマー
    this.blinkTimer = null

    //登場済みかどうかのフラグ
    this.isEntered = false

    //リスナー登録
    emitter.on(EVENT_TYPE.ACTION_AVATAR_UPDATE, this.updateAvatar.bind(this))

    //リセット
    this.reset()

    //アバターアップデート
    this.updateAvatar(images)
  }

  /**
   * リセット
   */
  reset() {
    // console.log('AvatarView.reset()')

    //フラグ
    this.isEntered = false

    //まばたき停止
    if (this.blinkTimer) {
      clearTimeout(this.blinkTimer)
      this.blinkTimer = null
    }

    //アバターリセット
    this.resetAvatar()
  }

  /**
   * アバターの表示をリセット
   */
  resetAvatar() {
    if (!this.mc) return
    // console.log('AvatarView.resetAvatar()')

    //アニメーション初期表示
    this.mc.gotoAndStop(0)

    //位置初期化
    TweenMax.killTweensOf(this.mc)
    TweenMax.set(this.mc, { y: 0 })
  }

  /**
   * 登場
   */
  enter() {
    // console.log('AvatarView.enter()')

    //フラグ
    this.isEntered = true

    //登場アニメーション
    if (this.mc) {
      TweenMax.killTweensOf(this.mc)
      TweenMax.to(this.mc, 0.3, {
        y: -16,
        ease: 'sine.out',
        delay: 0.0,
      })
      TweenMax.to(this.mc, 0.3, {
        y: 0,
        ease: 'sine.in',
        delay: 0.3,
        onComplete: this.blink.bind(this),
      })
    }
  }

  // ---------------------------------------------------------------------------------------------------------
  // --	アバター変更
  // ---------------------------------------------------------------------------------------------------------

  updateAvatar(images) {
    //古いアニメーションを削除
    if (this.mc) {
      this.removeChild(this.mc)
      this.mc.destroy(true, true, true)
      this.mc = null
    }

    //まばたき停止
    if (this.blinkTimer) {
      clearTimeout(this.blinkTimer)
      this.blinkTimer = null
    }

    //アバター生成
    AssetsManager.createAvatar(images).then(avatar => {
      //MC生成
      this.mc = avatar
      this.mc.anchor.set(0.5, 1.0)
      this.mc.scale.set(0.204, 0.204)
      this.mc.animationSpeed = 0.1
      this.mc.gotoAndStop(0)
      this.addChild(this.mc)

      //リセット
      this.resetAvatar()

      //登場アニメーションから再開
      if (this.isEntered) {
        this.enter()
      }
    })
  }

  // ---------------------------------------------------------------------------------------------------------
  // --	まばたき関連
  // ---------------------------------------------------------------------------------------------------------

  blink() {
    let delay = 1000 + Math.random() * 9000
    this.blinkTimer = setTimeout(this.blinkAnimation.bind(this), delay)
  }

  blinkAnimation() {
    this.mc.gotoAndStop(1)
    TweenMax.delayedCall(0.1, () => {
      this.mc.gotoAndStop(0)
    })
    TweenMax.delayedCall(0.2, () => {
      this.mc.gotoAndStop(1)
    })
    TweenMax.delayedCall(0.3, () => {
      this.mc.gotoAndStop(0)
    })

    this.blink()
  }

  // ---------------------------------------------------------------------------------------------------------
}

export default AvatarView
