import React, { useState, useEffect, useContext, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useSpring, animated, useTransition } from 'react-spring'
import { useHistory, useLocation } from 'react-router-dom'

import useMount from '../../hooks/useMount'
import useUnmount from '../../hooks/useUnmount'

import LogoMytown from '../Logo_mytown'
import UserPoint from './UserPoint'
import UserStatus from './UserStatus'
import AvatarIcon from './AvatarIcon'
import BeforeLogin from './BeforeLogin'
import Menu from './Menu'
import Help from './Help'
import TopAvatar from './Avatar'
import Topics from './Topics'
import Copyright from '../Copyright'
import PageNavigation from './PageNavigation'
import FeatureBanner from './FeatureBanner'
import Consult from './Consult'
import RoomName from '../rooms/RoomName'
import MenuBox from './MenuBox'
import { Context } from '../../Context'
import { MAP } from '../../constants'
import emitter, { EVENT_TYPE } from '../../events'
import MyroomStatus from './MyroomStatus'

const AnimatedTopics = animated(Topics)
const AnimatedCopyright = animated(Copyright)

const GlobalNavigation = ({
  logined,
  onLogin,
  onLogout,
  onDisconnect,
  onCreate,
  onHelp,
  onPointHelp,
  pathname,
}) => {
  const history = useHistory()
  const { state } = useContext(Context)
  const [isStarted, setIsStarted] = useState(false)

  const onPageStart = useCallback(() => {
    setIsStarted(true)
  }, [])

  const onPageLeave = useCallback(() => {
    setIsStarted(false)
  }, [])

  useMount(async () => {
    emitter.on(EVENT_TYPE.PAGE_START, onPageStart)
    emitter.on(EVENT_TYPE.PAGE_LEAVE, onPageLeave)
  })

  useUnmount(() => {
    emitter.removeListener(EVENT_TYPE.PAGE_START, onPageStart)
    emitter.removeListener(EVENT_TYPE.PAGE_LEAVE, onPageLeave)
  })

  const isShow = useCallback(pathname => {
    const pathnames = pathname.split('/')
    return (
      pathnames.length <= 2 && pathname !== '/avatar' && pathname !== '/404'
    )
  }, [])

  const leftSpring = useSpring({
    to: { transform: 'translateX(0%)', opacity: 1 },
    from: { transform: 'translateX(-10%)', opacity: 0 },
  })

  const rightSpring = useSpring({
    to: { transform: 'translateX(0%)', opacity: 1 },
    from: { transform: 'translateX(10%)', opacity: 0 },
  })

  const transition = useTransition({ pathname, isStarted }, pathname, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    unique: true,
  })

  return (
    <div className="globalNavigation">
      {transition.map(({ item, props, key }) => {
        return (
          item.isStarted && (
            <animated.div style={props} key={key}>
              <RoomName
                pathname={item.pathname}
                onHome={() => history.push('/')}
              />
              <LogoMytown pathname={item.pathname} />

              <FeatureBanner pathname={item.pathname} />

              <User
                logined={logined}
                pathname={item.pathname}
                point={state.userData.userPoint}
                rank={state.userData.userRank}
                onHelp={onPointHelp}
                onLogin={onLogin}
                onCreate={onCreate}
                pathname={item.pathname}
              />

              <Menu
                isLogin={logined}
                onLogin={onLogin}
                onLogout={onLogout}
                onDisconnect={onDisconnect}
                pathname={item.pathname}
              />
              <Help onClick={onHelp} pathname={item.pathname} />
              <Consult pathname={item.pathname} />
              <TopAvatar pathname={item.pathname} />
              <Topics pathname={item.pathname} />
              <Copyright pathname={item.pathname} />
              <PageNavigation pathname={item.pathname} />
            </animated.div>
          )
        )
      })}
    </div>
  )
}

GlobalNavigation.propTypes = {
  logined: PropTypes.bool.isRequired,
  onLogin: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
  onDisconnect: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  onHelp: PropTypes.func.isRequired,
  onPointHelp: PropTypes.func.isRequired,
  pathname: PropTypes.string.isRequired,
}

const User = ({
  point,
  rank,
  onHelp,
  onLogin,
  onCreate,
  logined,
  pathname,
}) => {
  const paths = ['/', '/news', '/cafe', '/school', '/park', '/house']
  return (
    <>
      {paths.includes(pathname) && (
        <>
          {logined ? (
            <>
              <>
                <UserPoint point={point} rank={rank} onHelp={onHelp} />
                <UserStatus />
                <AvatarIcon />
              </>
            </>
          ) : (
            <>
              <BeforeLogin onLogin={onLogin} onCreateAvatar={onCreate} />
            </>
          )}
        </>
      )}
      {pathname === '/myroom/main' ? (
        <>
          <MyroomStatus point={point} />
        </>
      ) : null}
    </>
  )
}

export default GlobalNavigation
