import React, { useContext, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  Redirect,
} from 'react-router-dom'
import { useTransition, animated } from 'react-spring'

import cssImport from './utils/cssImport'
import emitter, { EVENT_TYPE } from './events/index'

import {
  transitionInitialize,
  mapInitialize,
  chestInitialize,
  mapPagePause,
  wipeOut,
  wipeIn,
  mapPageMove,
} from './pixi'
import { Context } from './Context'
import { ActionTypes, meta } from './constants'
import { fetchUser, logout, fetchTreasureChestState } from './api'

import createImage from './utils/createImage'

import GlobalNavigation from './components/ui/GlobalNavigation'

import wrapPromise from './utils/wrapPromise'
import useMount from './hooks/useMount'
import useGlobalModal from './hooks/useGlobalModal'

import PageName from './pixi/constants/PageName'

const Main = loadable(() => import('./pages/Main'))
const Avatar = loadable(() => import('./pages/Avatar'))
const AvatarCreated = loadable(() => import('./components/pc/AvatarCreated'))
const Rooms = loadable(() => import('./pages/Rooms'))
const MyRoom = loadable(() => import('./pages/MyRoom'))
const MyRoomIntro = loadable(() => import('./pages/MyRoomIntro'))
const Page404 = loadable(() => import('./components/pc/Page404'))

const fetchAppResource = () => {
  const promise = new Promise(async resolve => {
    await cssImport('index.scss')

    await transitionInitialize()
    const result = await fetchUser()

    await mapInitialize({
      // Pixiにデータ渡す
      avatarImages: Object.values(result.userData.avatarPath).map(createImage),
      bannerImages: result.bannerData.map(({ image }) => createImage(image)),
    })

    await cssImport('components/modal/ModalButton.scss')
    await cssImport('components/topics/TopicsArea.scss')
    await cssImport('components/topics/TopicsBox.scss')
    await cssImport('components/ui/MenuBox.scss')
    await cssImport('components/ui/IconArrow.scss')
    await cssImport('components/modal/Shade.scss')
    await cssImport('components/modal/ModalArea.scss')
    await cssImport('components/modal/ModalButton.scss')
    await cssImport('components/avatar/Login.scss')
    await cssImport('components/rooms/RoomsName.scss')
    await cssImport('components/ui/TopAvatar.scss')
    await cssImport('components/ui/UserPoint.scss')
    await cssImport('components/ui/UserStatus.scss')
    await cssImport('components/ui/AvatarIcon.scss')
    await cssImport('components/ui/Menu.scss')
    await cssImport('components/ui/ShareButtons.scss')
    await cssImport('components/ui/Help.scss')
    await cssImport('components/ui/Topics.scss')
    await cssImport('components/ui/copyright.scss')
    await cssImport('components/modal/Point.scss')
    await cssImport('components/modal/Guide.scss')
    await cssImport('components/modal/Secret.scss') // CHANGED secret word item
    await cssImport('components/alert/Alert.scss')
    await cssImport('components/ui/GlobalNavigation.scss')
    await cssImport('components/ui/LogoMyTown.scss')
    await cssImport('components/ui/PageNavigation.scss')
    await cssImport('components/ui/FeatureBanner.scss')
    await cssImport('components/ui/Consult.scss')
    await cssImport('components/myroom/MyroomIntro.scss')
    await cssImport('components/myroom/IntroModal.scss') // マイルームモーダルのcss
    await cssImport('components/myroom/MyroomStatus.scss')

    resolve(result)
  })

  return wrapPromise(promise)
}

const fetchChestStatus = () => {
  const promise = new Promise(async resolve => {
    const result = await fetchTreasureChestState()

    await chestInitialize({
      // Pixiにデータ渡す
      chestStatus: result,
    })

    resolve(result)
  })

  return wrapPromise(promise)
}

const wait = millisecond =>
  new Promise(resolve => setTimeout(() => resolve(), millisecond))

const appResource = fetchAppResource()
const chestStatus = fetchChestStatus()

function App() {
  const { state, dispatch } = useContext(Context)

  const result = appResource.read()
  const chestResult = chestStatus.read()

  const history = useHistory()
  const location = useLocation()

  useEffect(() => {
    dispatch({ type: ActionTypes.setUserData, payload: result })
  }, [dispatch, result])

  useEffect(() => {
    if (!chestResult.open) {
      dispatch({ type: ActionTypes.setChestStatus, payload: chestResult })
    }
  }, [dispatch, chestResult])

  const modalComponent = useGlobalModal()

  const loaded = useRef(false)
  const wipeOuted = useRef(true)
  const isNavigation = useRef(false)

  useMount(() => {
    emitter.on(EVENT_TYPE.PAGE_ASSET_LOAD_COMPLETE, () => {
      loaded.current = true
    })
    emitter.on(EVENT_TYPE.PAGE_ASSET_LOAD_START, () => {
      loaded.current = false
    })
  })

  const transitions = useTransition(
    location,
    ({ pathname }) => {
      const roomRe = /\/(house|cafe|school|park|myroom\/main)(\/.+?)?/
      if (roomRe.test(pathname)) {
        const res = roomRe.exec(pathname)
        return res[1]
      }
      if (pathname === '/' || pathname === '/news') {
        return '/'
      }

      return pathname
    },
    {
      from: { visibility: 'hidden' },
      enter: item => async (next, cancel) => {
        loaded.current = false
        while (!loaded.current || !wipeOuted.current) {
          await wait(200)
        }
        await next({ visibility: 'visible' })
        const mapName = PageName.toSymbolFromPath(item.pathname)
        let firstAccess = null
        if (mapName) {
          firstAccess = await mapPageMove(mapName)
        }
        await wait(300)
        await wipeIn()
        emitter.emit(EVENT_TYPE.PAGE_START, firstAccess)
      },
      leave: item => async (next, cancel) => {
        emitter.emit(EVENT_TYPE.PAGE_LEAVE)
        mapPagePause()
        wipeOuted.current = false
        await wipeOut()
        wipeOuted.current = true
        await next({ visibility: 'hidden' })
      },
    }
  )

  return (
    <>
      <GlobalNavigation
        logined={state.userData.userId !== null}
        pathname={location.pathname}
        onLogin={() =>
          dispatch({ type: ActionTypes.setModal, payload: 'Login' })
        }
        onCreate={() => history.push('/avatar')}
        onHelp={() => dispatch({ type: ActionTypes.setModal, payload: 'Help' })}
        onPointHelp={() =>
          dispatch({ type: ActionTypes.setModal, payload: 'Point' })
        }
        onLogout={() => {
          logout()
        }}
        onDisconnect={() => {
          dispatch({ type: ActionTypes.setModal, payload: 'Disconnect' })
        }}
      />
      {transitions.map(({ item, props, key }) => (
        <animated.div
          className="App"
          style={{
            ...props,
          }}
          key={key}
        >
          <Switch location={item}>
            <Route path="/404" component={Page404} />
            <Route path="/avatar/created" component={AvatarCreated} />
            <Route exact path="/avatar" component={Avatar} />
            <Route exact path={['/', '/news']} component={Main} />
            <Route
              path={['/house/:id?', '/cafe/:id?', '/school/:id?', '/park/:id?']}
              component={Rooms}
            />
            <Route exact path="/myroom" component={MyRoomIntro} />
            <Route path="/myroom/main/:id?" component={MyRoom} />
            <Route path="*" component={Page404} />
          </Switch>
        </animated.div>
      ))}
      {modalComponent}
    </>
  )
}

export default App

App.propTypes = {
  device: PropTypes.string.isRequired,
}
