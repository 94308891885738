import React from 'react'
import PropTypes from 'prop-types'
import FeatureBanner01 from '../../img/banner/FeatureBanner_Consult.png'

const FeatureBanner = ({ pathname }) => {
  const paths = ['/', '/news']
  return (
    <div className="FeatureBanner">
      {paths.includes(pathname) && (
        <ul className="FeatureBanner__List">
          <li>
            <a href="/consult/" target="_blank" rel="noopener noreferrer">
              <img src={FeatureBanner01} alt="みらい相談室" />
            </a>
          </li>
        </ul>
      )}
    </div>
  )
}

FeatureBanner.propTypes = {
  pathname: PropTypes.string.isRequired,
}

export default FeatureBanner
