import * as PIXI from 'pixi.js-legacy'
import { TweenMax } from 'gsap'
import { getDevice } from '../../utils/deviceDetect'
import emitter, { EVENT_TYPE } from '../../events'
import AssetsManager from '../data/AssetsManager'

/**
 * LoadingViewクラス
 */
class LoadingView extends PIXI.Container {
  // ---------------------------------------------------------------------------------------------------------
  // --	LoadingView
  // ---------------------------------------------------------------------------------------------------------

  /**
   * [constructor description]
   */
  constructor() {
    super()

    //ループ用のタイマー
    this.loopTimer = null

    //コンテナ配置
    const container = new PIXI.Container()
    this.addChild(container)
    //SP
    if (getDevice() === 'sp') {
      const opS = 226 / 904
      container.scale.set(opS, opS)
      container.x = -226 / 2
      container.y = -51.5 / 2
    }
    //PC
    else {
      container.scale.set(0.5, 0.5)
      container.x = -904 / 4
      container.y = -206 / 4
    }

    //グリーン配置
    const green = AssetsManager.getSpriteByName('loading_green.png')
    container.addChild(green)

    //文字コンテナ
    this.charsContainer = new PIXI.Container()
    container.addChild(this.charsContainer)

    //文字配置
    this.chars = []
    for (let i = 0; i < 8; i++) {
      const char = AssetsManager.getSpriteByName(
        'loading_char0' + (i + 1) + '.png'
      )
      switch (i) {
        case 0:
          char.x = 132
          char.y = 0
          char.orgY = 0
          break
        case 1:
          char.x = 207
          char.y = 0
          char.orgY = 0
          break
        case 2:
          char.x = 303
          char.y = 0
          char.orgY = 0
          break
        case 3:
          char.x = 406
          char.y = 3
          char.orgY = 3
          break
        case 4:
          char.x = 505
          char.y = 0
          char.orgY = 0
          break
        case 5:
          char.x = 544
          char.y = 0
          char.orgY = 0
          break
        case 6:
          char.x = 635
          char.y = 0
          char.orgY = 0
          break
        case 7:
          char.x = 744
          char.y = 73
          char.orgY = 73
          break
        default:
      }
      this.charsContainer.addChildAt(char, 0)
      this.chars.push(char)
    }

    //リセット
    this.reset()

    //リスナー登録
    emitter.on(EVENT_TYPE.ACTION_SHOW_LOADING, this.show.bind(this))
  }

  /**
   * リセット
   */
  reset() {
    //タイマー停止
    if (this.loopTimer) {
      clearTimeout(this.loopTimer)
      this.loopTimer = null
    }

    //非表示
    TweenMax.killTweensOf(this)
    this.visible = false
    this.alpha = 0

    //テキストのアニメーションリセット
    const len = this.chars.length
    for (let i = 0; i < len; i++) {
      const char = this.chars[i]
      TweenMax.killTweensOf(char)
      TweenMax.set(char, { y: char.orgY })
    }
  }

  /**
   * 表示
   */
  show() {
    //表示
    this.visible = true
    this.alpha = 0

    //フェードイン
    TweenMax.killTweensOf(this)
    TweenMax.to(this, 0.6, {
      alpha: 1,
      ease: 'none',
      onComplete: this.playAnimation.bind(this),
    })
  }

  /**
   * アニメーション
   */
  playAnimation() {
    const len = this.chars.length
    for (let i = 0; i < len; i++) {
      const char = this.chars[i]
      TweenMax.killTweensOf(char)
      TweenMax.set(char, { y: char.orgY })
      TweenMax.to(char, 0.3, {
        y: char.orgY - 20,
        ease: 'power1.out',
        delay: 0.1 * i,
      })
      TweenMax.to(char, 0.3, {
        y: char.orgY,
        ease: 'power1.in',
        delay: 0.1 * i + 0.3,
      })
    }

    //ループ
    this.loopTimer = setTimeout(() => {
      this.playAnimation()
    }, 2000)
  }

  // ---------------------------------------------------------------------------------------------------------
}

export default LoadingView
