import EventEmitter from 'eventemitter3'

const emitter = new EventEmitter()

export default emitter

export const PAGE_MOVE = 'PageMove'

export const EVENT_TYPE = {
  ACTION_START: 'ActionStart',
  ACTION_SHOW_LOADING: 'ActionShowLoading',
  ACTION_WIPE_OUT: 'ActionWipeOut',
  ACTION_WIPE_IN: 'ActionWipeIn',
  ACTION_PAGE_MOVE: 'ActionPageMove',
  ACTION_PAGE_ENTER: 'ActionPageEnter',
  ACTION_PAGE_PAUSE: 'ActionPagePause',
  ACTION_PAGE_RESUME: 'ActionPageResume',
  ACTION_AVATAR_UPDATE: 'ActionAvatarUpdate',
  LANDING_ENTERED: 'LandingEntered',
  PIXI_INITIALIZED: 'PixiInitialized',
  PAGE_MOVE_START: 'PageMoveStart',
  PAGE_MOVE_COMPLETE: 'PageMoveComplete',
  PAGE_ASSET_LOAD_START: 'PageAssetLoadStart',
  PAGE_ASSET_LOAD_COMPLETE: 'PageAssetLoadComplete',
  PAGE_START: 'PageStart',
  PAGE_LEAVE: 'PageLeave',
  LOAD_START: 'LoadStart',
  LOAD_COMPLETE: 'LoadComplete',
  WIPE_OUT_START: 'WipeOutStart',
  WIPE_OUT_COMPLETE: 'WipeOutComplete',
  WIPE_IN_START: 'WipeInStart',
  WIPE_IN_COMPLETE: 'WipeInComplete',
  BANNER_SELECT: 'BannerSelect',
  PAGE_SELECT: 'PageSelect',
  POINTER_SELECT: 'PointerSelect',
  POINTER_ROLL_OVER: 'PointerRollOver',
  POINTER_ROLL_OUT: 'PointerRollOut',
  TREASURECHEST_OPEN: 'TreasureChestOpen',
  OPEN_PAGE_WITH_NEW_TAB: 'OpenPageWithNewtab',
}
