import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { useTransition } from 'react-spring'

import BtnMenu from '../../img/ui/Btn_menu.png'
import BtnMenuClose from '../../img/ui/Btn_menu__close.png'
import MenuBox from './MenuBox'

const Menu = ({ isLogin, onLogin, onLogout, onDisconnect, pathname }) => {
  const paths = [
    '/',
    '/news',
    '/school',
    '/cafe',
    '/house',
    '/park',
    '/myroom/main',
  ]
  const secondPagePath = ['/school', '/cafe', '/house', '/park']
  const menuClass = classNames('Menu', {
    '-isSecond': secondPagePath.includes(pathname),
    myroom: pathname === '/myroom/main',
  })

  const [isOpen, setToggle] = useState(false)
  useEffect(() => {
    setToggle(false)
  }, [])

  const menubox = useTransition(isOpen, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  })

  return (
    <div className={menuClass}>
      {paths.includes(pathname) && (
        <>
          <button
            type="button"
            className="Menu_btn"
            aria-expanded={isOpen}
            aria-controls="menubox"
            onClick={() => setToggle(!isOpen)}
          >
            <img src={isOpen ? BtnMenuClose : BtnMenu} alt="" />
          </button>

          {menubox.map(
            ({ item, key, props }) =>
              item && (
                <MenuBox
                  key={key}
                  style={props}
                  isLogin={isLogin}
                  isOpen={!isOpen}
                  onClose={() => setToggle(false)}
                  onLogin={onLogin}
                  onLogout={onLogout}
                  onDisconnect={onDisconnect}
                  pathname={pathname}
                />
              )
          )}
        </>
      )}
    </div>
  )
}

Menu.propTypes = {
  isLogin: PropTypes.bool.isRequired,
  onLogin: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
  onDisconnect: PropTypes.func.isRequired,
}

export default Menu
