import React from 'react'
import PropTypes from 'prop-types'
import { animated } from 'react-spring'

import ModalTitle from '../../img/modal/Modal_title__point.png'
import useModalIntro from '../../hooks/useModalIntro'

const Point = ({ children }) => {
  const modalStyle = useModalIntro()

  return (
    <section className="ModalPoint">
      <animated.div style={modalStyle} className="_Modal -type_1">
        <div className="__wrap">
          <h1 className="__title">
            <img src={ModalTitle} alt="「マイタウンポイント」って？ " />
          </h1>

          <div className="ModalPoint_list">
            <div className="ModalPoint_item">
              <div className="__img">
                <img src="../img/Img_howtoPoint01.png" alt="" />
              </div>
              <div className="__text">
                <h2>ポイントをためるには？</h2>
                <p>
                  SNSアカウントでログイン連携をすると、1日1ポイント毎日貯まります。マイタウンの中にある宝箱を見つけるとさらにポイントがもらえるよ！
                </p>
              </div>
            </div>
            <div className="ModalPoint_item">
              <div className="__img">
                <img src="../img/Img_howtoPoint02.png" alt="" />
              </div>
              <div className="__text">
                <h2>ポイントでパーツを手に入れよう！</h2>
                <p>
                  ためたポイントに応じて、自身のアバターで使える髪型や服、小物などのスペシャルパーツが解放されます。ポイントをたくさんためてもっと魅力的にしよう！
                </p>
              </div>
            </div>
          </div>
        </div>
      </animated.div>
      {children}
    </section>
  )
}

Point.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Point
