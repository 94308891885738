import * as PIXI from 'pixi.js-legacy'
import { TweenMax } from 'gsap'
import emitter, { EVENT_TYPE } from '../../events'
import AssetsManager from '../data/AssetsManager'
import DataManager from '../data/DataManager'

/**
 * BannerViewクラス
 */
class BannerView extends PIXI.Container {
  // ---------------------------------------------------------------------------------------------------------
  // --	BannerView
  // ---------------------------------------------------------------------------------------------------------

  /**
   * コンストラクタ
   */
  constructor() {
    super()

    //位置
    this.x = 194
    this.y = 572

    //SP版のクリック判定用変数
    this.touchStartPos = new PIXI.Point()
    this.touchStartTime = 0

    //スライド用タイマー
    this.slideTimer = null

    //バナーの数
    this.total = 0

    //傾斜
    this.radian = -30 * (Math.PI / 180)

    //バナー用コンテナ
    this.bannerContainer = new PIXI.Container()
    this.addChild(this.bannerContainer)

    //マスク配置
    const maskShape = new PIXI.Graphics()
    maskShape.beginFill(0x000000)
    maskShape.moveTo(120, 70)
    maskShape.lineTo(0, 139.3)
    maskShape.lineTo(0, 69.3)
    maskShape.lineTo(120, 0)
    maskShape.lineTo(120, 70)
    maskShape.endFill()
    maskShape.x = -60.5
    maskShape.y = -70
    this.addChild(maskShape)
    this.bannerContainer.mask = maskShape

    //ヒットエリア
    this.ha = new PIXI.Graphics()
    this.ha.beginFill(0x000000)
    this.ha.moveTo(120, 70)
    this.ha.lineTo(0, 139.3)
    this.ha.lineTo(0, 69.3)
    this.ha.lineTo(120, 0)
    this.ha.lineTo(120, 70)
    this.ha.endFill()
    this.ha.x = -60.5
    this.ha.y = -70
    this.ha.buttonMode = true
    this.ha.interactive = false
    this.ha.alpha = 0
    this.addChild(this.ha)

    //選択
    this.ha.on('click', () => {
      emitter.emit(EVENT_TYPE.BANNER_SELECT)
    })

    //入力（SP）
    this.ha.on('touchstart', e => {
      const date = new Date()
      this.touchStartTime = date.getTime()
      this.touchStartPos = e.data.getLocalPosition(
        DataManager.getData('mapRoot')
      )
    })
    this.ha.on('touchend', e => {
      //時間とタッチ位置で判定
      const date = new Date()
      const currentTime = date.getTime()
      const spendTime = currentTime - this.touchStartTime
      const currentPos = e.data.getLocalPosition(DataManager.getData('mapRoot'))
      const distance = Math.sqrt(
        Math.pow(this.touchStartPos.x - currentPos.x, 2) +
          Math.pow(this.touchStartPos.y - currentPos.y, 2)
      )

      //クリック判定
      if (distance <= 30 && spendTime <= 1000) {
        emitter.emit(EVENT_TYPE.BANNER_SELECT)
      }
    })

    //Viewの初期化
    this.initView()
  }

  /**
   * 初期化
   */
  initView() {
    AssetsManager.loadBannerAssets().then(banners => {
      //バナー配置
      this.total = banners.length
      for (let i = 0; i < this.total; i++) {
        const banner = banners[i]
        let scale = 112 / banner.width
        banner.scale.set(scale, scale)
        banner.skew.set(0, this.radian)
        banner.anchor.set(0.5, 0.5)
        this.bannerContainer.addChild(banner)
      }

      //変数初期化
      this.currentIndex = 0

      //表示位置初期化
      for (let i = 0; i < this.total; i++) {
        const banner = this.bannerContainer.getChildAt(i)
        banner.x = 140 * Math.cos(this.radian) * i
        banner.y = 140 * Math.sin(this.radian) * i
      }
    })
  }

  /**
   * リセット
   */
  reset() {
    //スライド停止
    if (this.slideTimer) {
      clearTimeout(this.slideTimer)
      this.slideTimer = null
    }

    //変数初期化
    this.currentIndex = 0

    //表示位置初期化
    for (let i = 0; i < this.total; i++) {
      const banner = this.bannerContainer.getChildAt(i)
      banner.x = 140 * Math.cos(this.radian) * i
      banner.y = 140 * Math.sin(this.radian) * i
    }

    //クリック不可
    this.ha.interactive = false
  }

  /**
   * スタート
   */
  start() {
    //クリック可能
    this.ha.interactive = true

    //スライド
    this.slideTimer = setTimeout(this.slide.bind(this), 9000)
  }

  /**
   * スライド
   */
  slide() {
    //次のスライド
    this.slideTimer = setTimeout(this.slide.bind(this), 9000)

    //バナーが2個以上ない場合は無視
    if (this.total <= 1) return

    //次のインデックス
    let nextIndex = this.currentIndex + 1
    if (nextIndex >= this.total) nextIndex = 0

    //現在のバナー
    const currentBanner = this.bannerContainer.getChildAt(this.currentIndex)
    TweenMax.killTweensOf(currentBanner)
    TweenMax.set(currentBanner, { x: 0, y: 0 })
    TweenMax.to(currentBanner, 0.6, {
      x: -140 * Math.cos(this.radian),
      y: -140 * Math.sin(this.radian),
      ease: 'power3.inout',
    })

    //次のバナー
    const nextBanner = this.bannerContainer.getChildAt(nextIndex)
    TweenMax.killTweensOf(nextBanner)
    TweenMax.set(nextBanner, {
      x: 140 * Math.cos(this.radian),
      y: 140 * Math.sin(this.radian),
    })
    TweenMax.to(nextBanner, 0.6, { x: 0, y: 0, ease: 'power3.inout' })

    //インデックス更新
    this.currentIndex = nextIndex
  }

  /**
   * 操作可能⇆不可能
   */
  setEnabled(bool) {
    this.ha.interactive = bool
  }

  // ---------------------------------------------------------------------------------------------------------
}

export default BannerView
