/**
 * 定数
 */

// eslint-disable-next-line import/prefer-default-export
export const ActionTypes = {
  setLoading: 'setLoading',
  setUserData: 'setUserData',
  setAvatarData: 'setAvatarData',
  nextUnlockParts: 'nextUnlockParts',
  changeCategory: 'changeCategory',
  selectedParts: 'selectedParts',
  pageMove: 'pageMove',
  pageLoadCompleted: 'pageLoadCompleted',
  resetAvatar: 'resetAvatar',
  showedIntro: 'showedIntro',
  showedMyroomStart: 'showedMyroomStart',
  showedMyroomCookpadCampaign: 'showedMyroomCookpadCampaign',
  showedMyroomAutumnCampaign: 'showedMyroomAutumnCampaign',
  showedCampaign: 'showedCampaign',
  showedMyroomCampaign: 'showedMyroomCampaign',
  showedAw2024Campaign: 'showedAw2024Campaign',
  showedAw2024CampaignMyroom: 'showedAw2024CampaignMyroom',
  showedPartsAddInfo: 'showedPartsAddInfo',
  showedPartsAddInfoMyroom: 'showedPartsAddInfoMyroom',
  showedCookpadContests: 'showedCookpadContests',
  showedAvatarHelp: 'showedAvatarHelp',
  showedPointNotification: 'showedPointNotification',
  setChestStatus: 'setChestStatus',
  setAwardUserPath: 'setAwardUserPath',
  setRoomData: 'setRoomData',
  setRoomParts: 'setRoomParts',
  isShowMyroomAlert: 'isShowMyroomAlert',
  isShowMyroomGuide: 'isShowMyroomGuide',
}

export const MAP = ['town', 'park', 'cafe', 'bank', 'house', 'school']

export const meta = {
  default: {
    title: 'マイタウン｜明治安田',
    description:
      '毎日をアクティブに、思いっきり輝く。そんなオトナ女子たちが暮らすのが、「マイタウン」。街中には、これからのライフスタイルをもっと豊かにしてくれるヒントがいっぱいです。さあ、あなたも今すぐ探検してみよう！',
  },
  myroom: {
    title: 'マイルーム｜マイタウン｜明治安田',
    description:
      'マイルームの中には日常に役立つ楽しいコンテンツが盛りだくさん。お気に入りをつめこんで、あなた好みのお部屋をつくろう！',
  },
}
