import * as PIXI from 'pixi.js-legacy'
import { TweenMax } from 'gsap'
import AssetsManager from '../data/AssetsManager'

/**
 * ItemViewクラス
 */
class ItemView extends PIXI.Container {
  // ---------------------------------------------------------------------------------------------------------
  // --	ItemView
  // ---------------------------------------------------------------------------------------------------------

  /**
   * コンストラクタ
   */
  constructor(model) {
    super()

    //モデルを保持
    this.model = model

    //Tween
    this.tween = null
    this.moveTween = null

    //mc
    this.item = AssetsManager.getItem(this.model)
    this.item.loop = false
    this.item.animationSpeed = this.model.animationSpeed
    this.addChild(this.item)

    //プレイ中かどうかのフラグ
    this.isPlaying = false

    //ループ処理
    this.item.onComplete = this.onComplete.bind(this)

    //位置
    this.x = this.model.position.x / 2
    this.y = this.model.position.y / 2

    //リセット
    this.reset()
  }

  reset() {
    //フラグ
    this.isPlaying = false

    //delay停止
    TweenMax.killTweensOf(this)
    this.tween = null

    //移動の初期化
    this.moveIndex = -1
    this.moveTween = null

    //アニメーションストップ
    this.item.gotoAndStop(0)

    //位置初期化
    this.x = this.model.position.x / 2
    this.y = this.model.position.y / 2
  }

  // ---------------------------------------------------------------------------------------------------------
  // --	登場
  // ---------------------------------------------------------------------------------------------------------

  enter() {
    TweenMax.killTweensOf(this)
    this.tween = TweenMax.to(this, this.model.startDelay, {
      onComplete: () => {
        this.isPlaying = true
        this.item.play()
        this.tween = null
        if (this.model.move) {
          this.move()
        }
      },
    })
  }

  // ---------------------------------------------------------------------------------------------------------
  // --	ポーズ・レジューム
  // ---------------------------------------------------------------------------------------------------------

  pause() {
    if (this.tween) {
      this.tween.pause()
    }
    if (this.moveTween) {
      this.moveTween.pause()
    }
    if (this.isPlaying) {
      this.item.stop()
    }
  }

  resume() {
    if (this.tween) {
      this.tween.resume()
    }
    if (this.moveTween) {
      this.moveTween.resume()
    }
    if (this.isPlaying) {
      this.item.play()
    }
  }

  // ---------------------------------------------------------------------------------------------------------
  // --	ループ
  // ---------------------------------------------------------------------------------------------------------
  onComplete() {
    this.isPlaying = false
    this.tween = TweenMax.to(this, this.model.loopDelay, {
      onComplete: () => {
        this.isPlaying = true
        this.item.gotoAndPlay(0)
        this.tween = null
        if (this.model.move && this.model.move.animationLink) {
          this.move()
        }
      },
    })
  }

  // ---------------------------------------------------------------------------------------------------------
  // --	移動
  // ---------------------------------------------------------------------------------------------------------

  move() {
    //位置配列
    const positions = this.model.move.positions

    //インデックス変更
    this.moveIndex++
    if (this.moveIndex > positions.length - 1) {
      this.moveIndex = 0
    }

    //移動情報
    const position = positions[this.moveIndex]

    //移動
    this.moveTween = TweenMax.to(this, position.duration, {
      x: position.pos.x / 2,
      y: position.pos.y / 2,
      ease: position.ease,
      onComplete: () => {
        this.moveTween = null
        if (this.model.move && !this.model.move.animationLink) {
          this.move()
        }
      },
    })
  }

  // ---------------------------------------------------------------------------------------------------------
}

export default ItemView
