import * as PIXI from 'pixi.js-legacy'
import { TweenMax } from 'gsap'
import emitter, { EVENT_TYPE } from '../../events'
import { getDevice } from '../../utils/deviceDetect'
import AssetsManager from '../data/AssetsManager'
import DataManager from '../data/DataManager'

/**
 * TreasureChestViewクラス
 */
class TreasureChestView extends PIXI.Container {
  // ---------------------------------------------------------------------------------------------------------
  // --	TreasureChestView
  // ---------------------------------------------------------------------------------------------------------

  /**
   * コンストラクタ
   */
  constructor(model, status) {
    super()

    //モデルを保持
    this.model = model

    this.close = this.model.close
    this.open = this.model.open
    this.status = status

    //SP版のクリック判定用変数
    this.touchStartPos = new PIXI.Point()
    this.touchStartTime = 0

    //close
    this.treasureChestClose = AssetsManager.getSpriteByName(
      `${this.close.name}.png`
    )

    this.treasureChestClose.x = this.close.position.x
    this.treasureChestClose.y = this.close.position.y
    this.treasureChestClose.buttonMode = true
    this.treasureChestClose.interactive = !this.status.isOpen ? true : false

    this.treasureChestClose.alpha = !this.status.isOpen ? 1 : 0

    this.treasureChestClose.anchor.set(0.5, 0.5)
    this.treasureChestClose.scale.set(0.5, 0.5)
    this.addChild(this.treasureChestClose)

    //open
    this.treasureChestOpen = AssetsManager.getSpriteByName(
      `${this.open.name}.png`
    )

    this.treasureChestOpen.x = this.open.position.x
    this.treasureChestOpen.y = this.open.position.y
    this.treasureChestOpen.alpha = this.status.isOpen ? 1 : 0

    this.treasureChestOpen.anchor.set(0.5, 0.5)
    this.treasureChestOpen.scale.set(0.5, 0.5)
    this.addChild(this.treasureChestOpen)

    //クリックアクション
    this.treasureChestClose.on('click', e => {
      if (this.status.isLogined) {
        this.treasureChestOpen.alpha = 1
        this.treasureChestClose.alpha = 0
        this.treasureChestClose.interactive = false
        emitter.emit(EVENT_TYPE.TREASURECHEST_OPEN, this.close.action)
      } else {
        emitter.emit(EVENT_TYPE.TREASURECHEST_OPEN, this.close.action)
      }
    })

    //入力（SP）
    this.treasureChestClose.on('touchstart', e => {
      //時間とタッチ位置を保持
      const date = new Date()
      this.touchStartTime = date.getTime()
      this.touchStartPos = e.data.getLocalPosition(
        DataManager.getData('mapRoot')
      )
    })
    this.treasureChestClose.on('touchend', e => {
      //時間とタッチ位置で判定
      const date = new Date()
      const currentTime = date.getTime()
      const spendTime = currentTime - this.touchStartTime
      const currentPos = e.data.getLocalPosition(DataManager.getData('mapRoot'))
      const distance = Math.sqrt(
        Math.pow(this.touchStartPos.x - currentPos.x, 2) +
          Math.pow(this.touchStartPos.y - currentPos.y, 2)
      )

      //クリック判定
      if (distance <= 30 && spendTime <= 1000) {
        if (this.status.isLogined) {
          this.treasureChestOpen.alpha = 1
          this.treasureChestClose.alpha = 0
          this.treasureChestClose.interactive = false
          emitter.emit(EVENT_TYPE.TREASURECHEST_OPEN, this.close.action)
        } else {
          emitter.emit(EVENT_TYPE.TREASURECHEST_OPEN, this.close.action)
        }
      }
    })

    //リセット
    this.reset()
  }

  reset() {
    //操作不可にする
    // this.setEnabled(false)
  }

  setEnabled(bool) {
    this.ha.interactive = bool
  }

  // ---------------------------------------------------------------------------------------------------------
}

export default TreasureChestView
