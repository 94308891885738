import React, { useContext } from 'react'

import AvatorFrame from '../../img/ui/AvatorFrame.png'
import AvatorBg from '../../img/ui/AvatarIcon_bg.png'

import { Context } from '../../Context'
import { useHistory } from 'react-router-dom'

const AvatarIcon = () => {
  const { state } = useContext(Context)
  const history = useHistory()
  const { avatarPath } = state.userData

  return (
    <div className="AvatarIcon">
      <div onClick={_ => history.push('/avatar')} className="AvatarIcon_wrap">
        <div className="__Frame">
          <img src={AvatorFrame} alt="" />
        </div>

        <div className="AvatarIcon_icon">
          <div className="__Img">
            <img src={avatarPath.open} alt="" />
          </div>
          <div className="__Bg">
            <img src={AvatorBg} alt="" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default AvatarIcon
