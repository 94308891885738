import React from 'react'
import PropTypes from 'prop-types'
import Guide from './Guide'

import Howto from '../../img/modal/Modal_img_howto.png'
import HowtoSP from '../../img/modal/Modal_img_howto__sp.png'

const TownGuide = ({ children }) => {
  return (
    <Guide
      image={() => {
        return (
          <>
            <img src={Howto} alt="" className="-pc" />
            <img src={HowtoSP} alt="" className="-sp" />
          </>
        )
      }}
    >
      {children}
    </Guide>
  )
}

TownGuide.propTypes = {
  children: PropTypes.node.isRequired,
}

export default TownGuide
