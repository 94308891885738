import React from 'react'
import PropTypes from 'prop-types'
import cssImport from '../../utils/cssImport'

import AvatorFrame from '../../img/ui/AvatorFrame_before.png'
import AvatorFrameSP from '../../img/ui/AvatorFrame_before__sp.png'
import AvatorDefault from '../../img/ui/Avatar_default.png'
import BtnMake from '../../img/ui/AvatorFrame_btn__makeAvatar.png'
import BtnLogin from '../../img/ui/AvatorFrame_btn__login.png'
import BtnMakeSP from '../../img/ui/AvatorFrame_btn__makeAvatar__sp.png'
import BtnLoginSP from '../../img/ui/AvatorFrame_btn__login__sp.png'

let loaded = false

const BeforeLogin = ({ onLogin, onCreateAvatar }) => {
  if (loaded) {
    return (
      <div className="BeforeLogin">
        <div className="BeforeLogin_wrap">
          <div className="__Frame">
            <img src={AvatorFrame} alt="" className="-pc" />
            <img src={AvatorFrameSP} alt="" className="-sp" />
          </div>
          <div className="__Avatar">
            <img src={AvatorDefault} alt="" />
          </div>
          <ul className="__Btn">
            <li>
              <button onClick={_ => onCreateAvatar()}>
                <img src={BtnMake} alt="キャラクターを作る" className="-pc" />
                <img src={BtnMakeSP} alt="キャラクターを作る" className="-sp" />
              </button>
            </li>
            <li>
              <button onClick={_ => onLogin()}>
                <img src={BtnLogin} alt="ログイン" className="-pc" />
                <img src={BtnLoginSP} alt="ログイン" className="-sp" />
              </button>
            </li>
          </ul>
        </div>
      </div>
    )
  }

  throw new Promise(async resolve => {
    await cssImport('components/ui/BeforeLogin.scss')

    loaded = true
    resolve()
  })
}

BeforeLogin.propTypes = {
  onLogin: PropTypes.func,
  onCreateAvatar: PropTypes.func,
}

export default BeforeLogin
