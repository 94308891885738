import MapView from './MapView'
import emitter, { EVENT_TYPE } from '../../events'

/**
 * マップ管理用クラス
 */
class MapManager {
  // ---------------------------------------------------------------------------------------------------------
  // --	MapManager
  // ---------------------------------------------------------------------------------------------------------

  /**
   * コンストラクタ
   */
  constructor() {
    //変数初期化
    this.maps = new Map()
    this.currentPage = null
  }

  // ---------------------------------------------------------------------------------------------------------
  // --	マップ取得＆初期化
  // ---------------------------------------------------------------------------------------------------------
  /**
   * マップを取得
   */
  async getMap(pageName) {
    if (this.maps.has(pageName)) {
      return this.maps.get(pageName)
    }

    const map = new MapView(pageName)
    this.maps.set(pageName, map)
    await map.loadAssets()
    map.initView()
    return map
  }

  // ---------------------------------------------------------------------------------------------------------
  // --	ポーズ＆レジューム
  // ---------------------------------------------------------------------------------------------------------
  /**
   * ポーズ
   */
  pause() {
    if (this.currentPage) this.currentPage.pause()
  }

  /**
   * レジューム
   */
  resume() {
    if (this.currentPage) this.currentPage.resume()
  }

  // ---------------------------------------------------------------------------------------------------------
  // --	ポインターの表示制御
  // ---------------------------------------------------------------------------------------------------------
  hidePointer() {
    if (this.currentPage) this.currentPage.hidePointer()
  }

  showPointer() {
    if (this.currentPage) this.currentPage.showPointer()
  }

  // ---------------------------------------------------------------------------------------------------------
  // --	登場
  // ---------------------------------------------------------------------------------------------------------
  enter() {
    if (this.currentPage) this.currentPage.enter()
  }

  // ---------------------------------------------------------------------------------------------------------
  // --	画面遷移
  // ---------------------------------------------------------------------------------------------------------
  /**
   * マップを変更
   */
  async pageMove(pageName) {
    // console.log('pageMove:', pageName)
    //イベント発行
    emitter.emit(EVENT_TYPE.PAGE_MOVE_START)

    //表示中のページがある場合
    if (this.currentPage) {
      this.pageLeave()
    }
    await this.pageEnter(pageName)

    // console.log('complete')
    emitter.emit(EVENT_TYPE.PAGE_MOVE_COMPLETE)
  }

  /**
   * 現在のページを離れる
   */
  pageLeave() {
    //現在のページをポーズ
    this.currentPage.pause()

    //前のページをリセット
    this.currentPage.reset()

    this.currentPage = null
  }

  /**
   * 次のページ登場
   */
  async pageEnter(pageName, firstAccess = false) {
    //次のページを取得
    const map = await this.getMap(pageName)

    //現在のマップを保持
    this.currentPage = map

    //表示準備
    this.currentPage.ready()

    //DEBUG
    // if (!firstAccess) return

    return firstAccess
  }

  // ---------------------------------------------------------------------------------------------------------
}

export default new MapManager()
