import React from 'react'
import PropTypes from 'prop-types'

import ShareTitle from '../../img/ui/Sns_ttl.png'
import ShareIconLine from '../../img/ui/Icon_line.png'
import ShareIconFb from '../../img/ui/Icon_fb.png'
import ShareIconTw from '../../img/ui/Icon_tw.png'

const ShareButtons = ({ twitter, facebook, line }) => {
  return (
    <div className="ShareButtons">
      <b>
        <img src={ShareTitle} alt="シェア" />
      </b>
      <ul>
        <li>
          <a href={twitter} target="_blank" rel="noopener noreferrer">
            <img src={ShareIconTw} alt="Twitterでシェア" />
          </a>
        </li>
        <li>
          <a href={facebook} target="_blank" rel="noopener noreferrer">
            <img src={ShareIconFb} alt="Facebookでシェア" />
          </a>
        </li>
        <li>
          <a href={line} target="_blank" rel="noopener noreferrer">
            <img src={ShareIconLine} alt="LINEでシェア" />
          </a>
        </li>
      </ul>
    </div>
  )
}

ShareButtons.propTypes = {
  line: PropTypes.string.isRequired,
  twitter: PropTypes.string.isRequired,
  facebook: PropTypes.string.isRequired,
}

export default ShareButtons
