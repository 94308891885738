import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import NameApartment from '../../img/room/RoomName_apartment.png' // マンション
import NameCafe from '../../img/room/RoomName_cafe.png' // カフェ
import NamePark from '../../img/room/RoomName_park.png' // 公園
import NameSchool from '../../img/room/RoomName_school.png' // パーク
import NameMyRoom from '../../img/myroom/page_name.png' // マイルーム
import BtnBack from '../../img/room/Btn_back.png'

const RoomName = ({ onHome, pathname }) => {
  const [NameImg, Name] = useMemo(() => {
    switch (pathname) {
      case '/cafe':
        return [NameCafe, 'カフェ']
      case '/park':
        return [NamePark, '公園']
      case '/house':
        return [NameApartment, 'マンション']
      case '/school':
        return [NameSchool, 'ラーニングスクール']
      case '/myroom/main':
        return [NameMyRoom, 'マイルーム']
      default:
        return [null, null]
    }
  }, [pathname])
  const roomName = classNames('RoomName_name', {
    myroom: pathname === '/myroom/main',
  })

  return (
    <div className={Name === 'マイルーム' ? 'RoomName myroom' : 'RoomName'}>
      {Name !== null && (
        <>
          <div className={roomName}>
            <img src={NameImg} alt={Name} />
          </div>
          {Name === 'マイルーム' ? null : (
            <div className="RoomName_back">
              <button onClick={onHome}>
                <img src={BtnBack} alt="もどる" />
              </button>
            </div>
          )}
        </>
      )}
    </div>
  )
}

RoomName.propTypes = {
  onHome: PropTypes.func.isRequired,
  pathname: PropTypes.string.isRequired,
}

export default RoomName
