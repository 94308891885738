import * as PIXI from 'pixi.js-legacy'
import { TweenMax } from 'gsap'
import { getDevice } from '../../utils/deviceDetect'
import DataManager from '../data/DataManager'
import AssetsManager from '../data/AssetsManager'
import ITEMS from '../constants/Items'
import POINTERS from '../constants/Pointer'
import TREASURECHEST from '../constants/TreasureChest'
import * as PageName from '../constants/PageName'
import RoomPointerView from './RoomPointerView'
import PointerView from './PointerView'
import TreasureChestView from './TreasureChestView'
import BannerView from './BannerView'
import { EVENT_TYPE } from '../../events'
import AvatarView from './AvatarView'
import ItemView from './ItemView'
import AwardPointerView from './AwardPointerView'
import SchooPointerView from './SchooPointerView'
import MyroomPointerView from './MyroomPointerView'

// import * as PageName from '../constants/PageName'

/**
 * MapViewクラス
 */
class MapView extends PIXI.Container {
  // ---------------------------------------------------------------------------------------------------------
  // --	MapView
  // ---------------------------------------------------------------------------------------------------------

  /**
   * [constructor description]
   */
  constructor(pageName) {
    super()

    // ページ名保持
    this.pageName = pageName

    // App参照
    this.app = DataManager.getData('back')

    this.treasureChest = DataManager.getData('treasureChest')

    // ステージに配置
    const mapRoot = DataManager.getData('mapRoot')
    mapRoot.addChild(this)

    // ロックフラグ
    this.isLock = false

    // アクティブかどうかのフラグ
    this.isActive = false

    // ロールオーバー中かどうかのフラグ
    this.isRollOver = false

    // ポーズ中かどうかのフラグ
    this.isPause = false

    this.isInit = false

    // マップ用コンテナ
    this.mapContainer = new PIXI.Container()
    this.addChild(this.mapContainer)

    // スクロール用コンテナ
    // 中心点ずらしの際はreset時に位置指定
    const device = getDevice()
    this.movableContainer = new PIXI.Container()
    if (device === 'pc') {
      this.movableContainer.x = 1000
      this.movableContainer.y = 1000
    }
    this.mapContainer.addChild(this.movableContainer)

    // 拡大縮小用コンテナ
    this.scalableContainer = new PIXI.Container()
    this.movableContainer.addChild(this.scalableContainer)

    // セーフエリア
    // 移動可能な範囲を指定
    // -------------------- WILL CHANGE -------------------- //
    // 会場中心にする場合、値を調整
    // Max移動可能にする場合は背景画像サイズ(0, 0, 1440, 1080)
    // if (this.pageName === PageName.MYROOM) {
    //   this.safeSize = new PIXI.Rectangle(0, 0, 3174, 1974)
    // } else {
    //   this.safeSize = new PIXI.Rectangle(0, 0, 1440, 1080)
    // }
    this.safeSize = new PIXI.Rectangle(0, 0, 1440, 1080)
    this.scaledSize = new PIXI.Rectangle()
    this.scaledSafeSize = new PIXI.Rectangle()

    // 移動範囲
    this.movableRange = new PIXI.Rectangle(0, 0, 0, 0)

    // ドラッグ用の変数
    this.isDragging = false
    this.dragPosXArray = []
    this.dragSpeedX = 0
    this.prevPos = null
  }

  /**
   * Assets読み込み
   */
  async loadAssets() {
    try {
      await AssetsManager.loadAssets(this.pageName)
    } catch (error) {
      console.log(`MapView.loadAssets().catch:${error}`)
    }
  }

  /**
   * Viewの初期化
   */
  initView() {
    // 背景配置
    this.bg = AssetsManager.getBg(this.pageName)
    this.scalableContainer.addChildAt(this.bg, 0)
    this.orgSize = new PIXI.Rectangle(0, 0, this.bg.width, this.bg.height)

    // バナー配置
    // if (this.pageName === PageName.TOWN) {
    //   this.bannerView = new BannerView()
    //   this.scalableContainer.addChild(this.bannerView)
    // }

    // アイテム配置
    this.itemContainer = new PIXI.Container()
    this.scalableContainer.addChild(this.itemContainer)
    const items = ITEMS.get(this.pageName)
    for (let i = 0; i < items.length; i++) {
      const item = new ItemView(items[i])
      this.itemContainer.addChild(item)
    }

    // 雲配置
    if (this.pageName === PageName.TOWN) {
      this.cloudContainer = new PIXI.Container()
      this.scalableContainer.addChild(this.cloudContainer)
      for (let i = 0; i < 4; i++) {
        const cloud = AssetsManager.getSpriteByName('town-cloud')
        cloud.anchor.set(0.5, 0.5)
        cloud.scale.set(0.5, 0.5)
        cloud.basePos = new PIXI.Point()
        switch (i) {
          case 0:
            cloud.basePos.x = 531 + 80
            cloud.basePos.y = 127 + 140
            break
          case 1:
            cloud.basePos.x = 718 + 80
            cloud.basePos.y = 661 + 140
            break
          case 2:
            cloud.basePos.x = 100
            cloud.basePos.y = 1020
            break
          case 3:
            cloud.basePos.x = -600
            cloud.basePos.y = 1200
            break
          default:
        }
        this.cloudContainer.addChild(cloud)
      }
    }

    // ポインター配置
    this.pointerContainer = new PIXI.Container()
    this.scalableContainer.addChild(this.pointerContainer)
    if (this.pageName === PageName.TOWN) {
      this.townContainer = new PIXI.Container()
      this.scalableContainer.addChildAt(this.townContainer, 1)
    }
    const models = POINTERS.get(this.pageName)
    for (let ii = 0; ii < models.length; ii++) {
      const model = models[ii]
      let pointer
      // eslint-disable-next-line no-nested-ternary
      if (this.pageName === PageName.TOWN) {
        if (model.name === 'award') {
          pointer = new AwardPointerView(model)
        } else if (model.name === 'schoo') {
          pointer = new SchooPointerView(model)
        } else if (model.name === 'myroom') {
          pointer = new MyroomPointerView(model)
        } else {
          pointer = new RoomPointerView(model)
        }
      } else {
        pointer = new PointerView(model)
      }

      this.pointerContainer.addChild(pointer)

      if (
        model.name !== 'award' ||
        model.name !== 'schoo' ||
        model.name !== 'myroom'
      ) {
        pointer.on(EVENT_TYPE.POINTER_ROLL_OVER, () => {
          this.isRollOver = true
        })
        pointer.on(EVENT_TYPE.POINTER_ROLL_OUT, () => {
          this.isRollOver = false
        })
      }

      if (this.pageName === PageName.TOWN) {
        if (
          model.name !== 'award' &&
          model.name !== 'schoo' &&
          model.name !== 'myroom'
        ) {
          this.townContainer.addChild(pointer.room)
        }
      }
    }

    // 宝箱配置
    // pageNameとBEからのplaceが一致したら
    if (this.pageName === PageName.toSymbol(this.treasureChest.place)) {
      this.chestContainer = new PIXI.Container()
      this.scalableContainer.addChild(this.chestContainer)
      const status = {
        isOpen: this.treasureChest.open,
        isLogined: this.treasureChest.uid,
      }
      const place = PageName.toSymbol(this.treasureChest.place)
      const { point } = this.treasureChest
      const chestModels = TREASURECHEST.get(place)
      const chestModel = point == 1 ? chestModels[0] : chestModels[1]

      const chests = new TreasureChestView(chestModel, status)
      this.chestContainer.addChild(chests)
    }

    // リサイズのハンドリング
    window.addEventListener('resize', this.onResize.bind(this))
    // window.onresize = this.onResize.bind(this)

    // ループ処理
    this.app.ticker.add(this.loop.bind(this))

    // SP版はドラッグ可能にする
    const device = getDevice()
    if (device === 'sp') {
      this.setDraggable()
    }

    // リセット
    this.reset()
  }

  /**
   * リセット
   */
  reset() {
    // フラグ初期化
    this.isActive = false
    this.isDragging = false
    this.isRollOver = false
    this.isPause = false

    // ドラッグの慣性初期化
    this.dragSpeedX = 0

    // 非表示
    this.visible = false

    // アバターリセット
    if (this.avatar) {
      this.avatar.reset()
      this.avatar = null
    }

    // バナーリセット
    if (this.bannerView) {
      this.bannerView.reset()
    }

    // コンテナの位置をリセット
    if (this.pageName !== PageName.TOWN) {
      this.movableContainer.x = 0
      this.movableContainer.y = 0
      // スマホ版のファーストビューの位置を調整する
      if (getDevice() === 'sp') {
        switch (this.pageName) {
          case PageName.PARK:
            this.movableContainer.x = 50
            break
          case PageName.SCHOOL:
            this.movableContainer.x = 100
            break
          default:
            this.movableContainer.x = 0
        }
      }
    }

    // ポインターリセット
    const len = this.pointerContainer.children.length
    for (let i = 0; i < len; i++) {
      const pointer = this.pointerContainer.getChildAt(i)
      pointer.reset()
    }
    TweenMax.killTweensOf(this.pointerContainer)
    TweenMax.set(this.pointerContainer, { alpha: 1 })

    // アイテムリセット
    const len2 = this.itemContainer.children.length
    for (let ii = 0; ii < len2; ii++) {
      const item = this.itemContainer.getChildAt(ii)
      if (item instanceof AvatarView) {
        // console.log('this is avatar')
      } else {
        item.reset()
      }
    }

    // 雲の位置をリセット
    if (this.cloudContainer) {
      const len = this.cloudContainer.children.length
      for (let i = 0; i < len; i++) {
        const cloud = this.cloudContainer.getChildAt(i)
        const radian = -30 * (Math.PI / 180)
        cloud.x = cloud.basePos.x - 500 * Math.cos(radian)
        cloud.y = cloud.basePos.y - 500 * Math.sin(radian)
      }
    }
  }

  /**
   * 表示準備
   */
  ready() {
    // スケールを初期化
    this.updateScale(0)

    // 表示
    this.visible = true

    // アバター配置
    if (this.pageName !== PageName.TOWN) {
      this.avatar = DataManager.getData('avatar')
      this.itemContainer.addChild(this.avatar)

      switch (this.pageName) {
        case PageName.PARK:
          this.avatar.x = 651 + 80
          this.avatar.y = 524 + 140
          break
        case PageName.CAFE:
          this.avatar.x = 701.5 + 80
          this.avatar.y = 564 + 140
          break
        case PageName.BANK:
          this.avatar.x = 562 + 80
          this.avatar.y = 576 + 140
          break
        case PageName.SCHOOL:
          this.avatar.x = 738.5 + 80
          this.avatar.y = 556 + 140
          break
        case PageName.HOUSE:
          this.avatar.x = 833 + 80
          this.avatar.y = 617 + 140
          break
        // case PageName.MYROOM:
        //   this.avatar.x = 833 + 80
        //   this.avatar.y = 617 + 140
        //   break
        default:
      }
    } else {
      this.avatar = null
    }
  }

  /**
   * エンター
   */
  enter() {
    // ポインター登場
    const len = this.pointerContainer.children.length
    for (let i = 0; i < len; i++) {
      const pointer = this.pointerContainer.getChildAt(i)
      pointer.enter()
    }

    // アニメーション再生開始
    const len2 = this.itemContainer.children.length
    for (let ii = 0; ii < len2; ii++) {
      const item = this.itemContainer.getChildAt(ii)
      if (item instanceof AvatarView) {
        // console.log('this is avatar')
      } else {
        item.enter()
      }
    }

    // アバター登場
    if (this.avatar) {
      this.avatar.enter()
    }

    // バナースタート
    if (this.bannerView) {
      this.bannerView.start()
    }

    // アクティブにする
    this.isActive = true
  }

  // ---------------------------------------------------------------------------------------------------------
  // --	ポーズとレジューム
  // ---------------------------------------------------------------------------------------------------------

  /**
   * ポーズ
   */
  pause() {
    // フラグ
    this.isPause = true

    // ポインターを操作不可にする
    const len = this.pointerContainer.children.length
    for (let i = 0; i < len; i++) {
      const pointer = this.pointerContainer.getChildAt(i)
      pointer.setEnabled(false)
    }

    // アニメーション再生停止
    const len2 = this.itemContainer.children.length
    for (let ii = 0; ii < len2; ii++) {
      const item = this.itemContainer.getChildAt(ii)
      if (item instanceof AvatarView) {
        // console.log('this is avatar')
      } else {
        item.pause()
      }
    }

    // バナー
    if (this.bannerView) {
      this.bannerView.setEnabled(false)
    }
  }

  /**
   * レジューム
   */
  resume() {
    // フラグ
    this.isPause = false

    // ポインターを操作可能にする
    const len = this.pointerContainer.children.length
    for (let i = 0; i < len; i++) {
      const pointer = this.pointerContainer.getChildAt(i)
      pointer.setEnabled(true)
    }

    // アニメーション再生再開
    const len2 = this.itemContainer.children.length
    for (let ii = 0; ii < len2; ii++) {
      const item = this.itemContainer.getChildAt(ii)
      if (item instanceof AvatarView) {
        // console.log('this is avatar')
      } else {
        item.resume()
      }
    }

    // バナー
    if (this.bannerView) {
      this.bannerView.setEnabled(true)
    }
  }

  // ---------------------------------------------------------------------------------------------------------
  // --	ポインターの表示制御
  // ---------------------------------------------------------------------------------------------------------

  hidePointer() {
    // ポインターを操作不可にする
    const len = this.pointerContainer.children.length
    for (let i = 0; i < len; i++) {
      const pointer = this.pointerContainer.getChildAt(i)
      pointer.setEnabled(false)
    }

    // コンテナごと非表示にする
    TweenMax.killTweensOf(this.pointerContainer)
    TweenMax.to(this.pointerContainer, 0.1, { alpha: 0, ease: 'none' })
  }

  showPointer() {
    // コンテナごと表示
    TweenMax.killTweensOf(this.pointerContainer)
    TweenMax.to(this.pointerContainer, 0.1, {
      alpha: 1,
      ease: 'none',
      onComplete: () => {
        // ポインターを操作可能にする
        if (!this.isPause) {
          const len = this.pointerContainer.children.length
          for (let i = 0; i < len; i++) {
            const pointer = this.pointerContainer.getChildAt(i)
            pointer.setEnabled(true)
          }
        }
      },
    })
  }

  // ---------------------------------------------------------------------------------------------------------
  // --	リサイズ関連
  // ---------------------------------------------------------------------------------------------------------

  /**
   * リサイズ
   */
  onResize() {
    if (!this.visible) return
    if (this.resizeTimer) {
      clearTimeout(this.resizeTimer)
    }
    this.resizeTimer = setTimeout(() => {
      // 拡大縮小
      this.updateScale()
    }, 300)
  }

  // 初期化、resize時に呼ばれる
  updateScale(duration = 0.3) {
    // ロック
    this.isLock = true

    // スクリーンサイズ
    this.screenWidth = this.app.renderer.width / this.app.renderer.resolution
    this.screenHeight = this.app.renderer.height / this.app.renderer.resolution

    // 拡大率PC
    if (getDevice() === 'pc') {
      if (
        this.screenWidth > this.orgSize.width ||
        this.screenHeight > this.orgSize.height
      ) {
        this.opScale = Math.max(
          this.screenWidth / this.orgSize.width,
          this.screenHeight / this.orgSize.height
        )
      } else {
        this.opScale = 1
      }
    }
    // 拡大率SP
    else {
      this.opScale = this.screenHeight / this.orgSize.height
    }

    // スケール後のサイズ
    this.scaledSize.width = this.orgSize.width * this.opScale
    this.scaledSize.height = this.orgSize.height * this.opScale
    this.scaledSafeSize.width = this.safeSize.width * this.opScale
    this.scaledSafeSize.height = this.safeSize.height * this.opScale

    // 移動範囲計算
    if (getDevice() === 'pc') {
      this.movableRange.width =
        this.safeSize.width * this.opScale - this.screenWidth
      this.movableRange.height =
        this.safeSize.height * this.opScale - this.screenHeight
    } else {
      this.movableRange.width =
        this.orgSize.width * this.opScale - this.screenWidth
      this.movableRange.height =
        this.orgSize.height * this.opScale - this.screenHeight
    }
    if (this.movableRange.width < 0) this.movableRange.width = 0
    if (this.movableRange.height < 0) this.movableRange.height = 0

    // 移動制限
    this.limitMovable(duration)

    // 拡大縮小Tween
    TweenMax.killTweensOf(this.scalableContainer)
    TweenMax.to(this.scalableContainer, duration, {
      x: -this.scaledSize.width / 2,
      y: -this.scaledSize.height / 2,
    })
    TweenMax.killTweensOf(this.scalableContainer.scale)
    TweenMax.to(this.scalableContainer.scale, duration, {
      x: this.opScale,
      y: this.opScale,
      onComplete: () => {
        this.isLock = false
      },
    })

    // センタリング
    TweenMax.killTweensOf(this.mapContainer)
    TweenMax.to(this.mapContainer, duration, {
      x: this.screenWidth / 2,
      y: this.screenHeight / 2,
    })
  }

  // ---------------------------------------------------------------------------------------------------------
  // --	ループ処理
  // ---------------------------------------------------------------------------------------------------------

  /**
   * ループ処理
   */
  loop() {
    if (this.isLock) return
    if (this.isPause) return
    if (!this.isActive) return

    // 雲のスクロール
    if (this.cloudContainer) {
      const speed = 0.5
      const radian = -30 * (Math.PI / 180)
      const len = this.cloudContainer.children.length
      for (let i = 0; i < len; i++) {
        const cloud = this.cloudContainer.getChildAt(i)
        cloud.x += speed * Math.cos(radian)
        cloud.y += speed * Math.sin(radian)
        if (cloud.y < -cloud.height / 2) {
          const taikaku =
            (this.orgSize.height + cloud.height) / Math.sin(radian)
          cloud.x += taikaku * Math.cos(radian)
          cloud.y += this.orgSize.height + cloud.height
        }
      }
    }

    // ロールオーバー中はマップのスクロールは停止
    if (this.isRollOver) return

    // PCの場合はマウス位置でスクロール
    if (getDevice() === 'pc') {
      // マウス位置
      const position = this.app.renderer.plugins.interaction.mouse.getLocalPosition(
        this
      )

      if (position.x < 0 || position.y < 0) return

      // マウスの位置から方向を計算
      const vec = new PIXI.Point(0, 0)
      vec.x = (position.x / this.screenWidth - 0.5) * 2
      if (vec.x > 0) {
        vec.x = vec.x <= 0.5 ? 0 : (vec.x - 0.5) * 2
      } else if (vec.x < 0) {
        vec.x = vec.x >= -0.5 ? 0 : (vec.x + 0.5) * 2
      }
      vec.y = (position.y / this.screenHeight - 0.5) * 2
      if (vec.y > 0) {
        vec.y = vec.y <= 0.5 ? 0 : (vec.y - 0.5) * 2
      } else if (vec.y < 0) {
        vec.y = vec.y >= -0.5 ? 0 : (vec.y + 0.5) * 2
      }

      // 移動
      this.movableContainer.x -= 5 * vec.x
      this.movableContainer.y -= 5 * vec.y
      this.limitMovable()
    }
    // SPはドラッグの慣性
    else {
      // ドラッグ中（座標を記録）
      if (this.isDragging) {
        this.dragPosXArray.push(this.movableContainer.x)
        if (this.dragPosXArray.length > 10) this.dragPosXArray.shift()
      }
      // 慣性移動
      else if (this.dragSpeedX !== 0) {
        this.dragSpeedX *= 0.95
        if (Math.abs(this.dragSpeedX) < 0.1) this.dragSpeedX = 0
        this.movableContainer.x += this.dragSpeedX
        this.limitMovable()
      }
    }
  }

  /**
   * スクロール制限
   */
  limitMovable(duration = 0) {
    // 横制限
    let opX = this.movableContainer.x
    if (this.movableContainer.x < -this.movableRange.width / 2) {
      opX = -this.movableRange.width / 2
    } else if (this.movableContainer.x > this.movableRange.width / 2) {
      opX = this.movableRange.width / 2
    }

    // 縦制限
    let opY = this.movableContainer.y
    if (this.movableContainer.y < -this.movableRange.height / 2) {
      opY = -this.movableRange.height / 2
    } else if (this.movableContainer.y > this.movableRange.height / 2) {
      opY = this.movableRange.height / 2
    }

    // Tween
    TweenMax.killTweensOf(this.movableRange)
    TweenMax.to(this.movableContainer, duration, { x: opX, y: opY })
  }

  // ---------------------------------------------------------------------------------------------------------
  // --	ドラッグ関連
  // ---------------------------------------------------------------------------------------------------------

  setDraggable() {
    this.interactive = true
    this.on('touchstart', this._onDragStart.bind(this))
  }

  _onDragStart(e) {
    if (this.isPause) return
    if (!this.isActive) return
    // フラグをたてる
    this.isDragging = true

    // タッチ位置を保存
    this.prevPos = e.data.getLocalPosition(this)

    // ドラッグ配列の初期化
    this.dragPosXArray = []

    // リスナー登録
    this.onDragMove = this._onDragMove.bind(this)
    this.onDragEnd = this._onDragEnd.bind(this)
    this.on('touchmove', this.onDragMove)
    this.on('touchend', this.onDragEnd)
    this.on('touchendoutside', this.onDragEnd)
  }

  _onDragMove(e) {
    if (this.isPause) return
    if (!this.isDragging) return

    // 移動距離計算
    const currentPos = e.data.getLocalPosition(this)
    const vecX = currentPos.x - this.prevPos.x
    const vecY = currentPos.y - this.prevPos.y

    // 移動
    this.movableContainer.x += vecX
    this.movableContainer.y += vecY
    this.limitMovable()

    // 位置保存
    this.prevPos = currentPos
  }

  _onDragEnd(e) {
    // フラグリセット
    this.isDragging = false

    // ドラッグスピードを計算
    if (this.dragPosXArray.length >= 10) {
      this.dragSpeedX =
        (this.dragPosXArray[this.dragPosXArray.length - 1] -
          this.dragPosXArray[0]) /
        this.dragPosXArray.length
    } else {
      this.dragSpeedX = 0
    }

    // リスナー解除
    this.off('touchmove', this.onDragMove)
    this.off('touchend', this.onDragEnd)
    this.off('touchendoutside', this.onDragEnd)
  }

  // ---------------------------------------------------------------------------------------------------------
}

export default MapView
