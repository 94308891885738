import * as PIXI from 'pixi.js-legacy'
import { TweenMax } from 'gsap'
import emitter, { EVENT_TYPE } from '../../events'
import { getDevice } from '../../utils/deviceDetect'
import AssetsManager from '../data/AssetsManager'
import DataManager from '../data/DataManager'

/**
 * RoomPointerViewクラス
 */
class RoomPointerView extends PIXI.Container {
  // ---------------------------------------------------------------------------------------------------------
  // --	RoomPointerView
  // ---------------------------------------------------------------------------------------------------------

  /**
   * コンストラクタ
   */
  constructor(model) {
    super()

    // モデルを保持
    this.model = model

    // 位置を保持
    this.position =
      getDevice() === 'pc' ? this.model.position.pc : this.model.position.sp

    // 位置を初期化
    this.x = this.position.x
    this.y = this.position.y

    // SP版のクリック判定用変数
    this.touchStartPos = new PIXI.Point()
    this.touchStartTime = 0

    // 建物生成
    this.room = AssetsManager.getSpriteByName(`room_${this.model.name}.png`)
    this.room.anchor.set(
      this.model.room_anchor.x / this.room.width,
      this.model.room_anchor.y / this.room.height
    )
    this.room.scale.set(0.5, 0.5)
    this.addChild(this.room)

    // ルームの位置
    this.roomX =
      this.model.room_pos.x / 2 + this.room.width * this.room.anchor.x
    this.roomY =
      this.model.room_pos.y / 2 + this.room.height * this.room.anchor.y
    this.room.x = this.roomX
    this.room.y = this.roomY

    // ポインターコンテナ
    this.pointerContainer = new PIXI.Container()
    this.addChild(this.pointerContainer)

    // ラベル配置
    this.label = AssetsManager.getSpriteByName(`label_${this.model.name}.png`)

    this.label.anchor.set(0.0, 1.0)
    if (getDevice() === 'pc') {
      this.label.scale.set(0.5, 0.5)
      this.label.x = 2
      this.label.y = 0.5
    } else {
      this.label.x = 2
      this.label.y = 0.5
    }
    this.pointerContainer.addChild(this.label)

    // キャラ配置
    this.chara = AssetsManager.getSpriteByName(
      `hover_chara_${this.model.name}.png`
    )
    this.chara.anchor.set(0.5, 1.0)
    if (getDevice() === 'pc') {
      this.chara.scale.set(0.5, 0.5)
      this.chara.x = 0
      this.chara.y = 1.5
    } else {
      this.chara.x = 0.5
      this.chara.y = 0.5
    }
    this.pointerContainer.addChild(this.chara)

    // コメント配置
    // 有料版テクスチャーピッカーにしたら修正
    this.comment = AssetsManager.getSpriteByName(
      `hover_comment_${this.model.name}.png`
    )
    this.comment.anchor.set(0.5, 0.0)
    if (getDevice() === 'pc') {
      this.comment.scale.set(0.5, 0.5)
      this.comment.x = 0
      this.comment.y = 11.5
    } else {
      this.comment.x = 1
      this.comment.y = 20.5
    }
    this.pointerContainer.addChild(this.comment)

    // ポインター配置
    this.pointer = AssetsManager.getSpriteByName('town_pointer.png')
    this.pointer.anchor.set(0.5, 0.5)
    if (getDevice() === 'pc') {
      this.pointer.scale.set(0.5, 0.5)
    }
    this.pointerContainer.addChild(this.pointer)

    // ヒットエリアコンテナ
    this.haContainer = new PIXI.Container()
    this.haContainer.buttonMode = true
    this.haContainer.interactive = true
    this.haContainer.alpha = 0.0
    this.addChild(this.haContainer)

    //ヒットエリアのレクタングル計算
    let minX = 9999
    let maxX = -9999
    let minY = 9999
    let maxY = -9999

    const labelBounds = this.label.getLocalBounds()
    minX = labelBounds.x / 2
    maxX = minX + labelBounds.width / 2
    minY = labelBounds.y / 2
    maxY = minY + labelBounds.height / 2

    const roomMinX = this.model.room_pos.x / 2 - this.position.x
    const roomMaxX = roomMinX + this.room.width
    const roomMinY = this.model.room_pos.y / 2 - this.position.y
    const roomMaxY = roomMinY + this.room.height

    if (roomMinX < minX) minX = roomMinX
    if (roomMaxX > maxX) maxX = roomMaxX
    if (roomMinY < minY) minY = roomMinY
    if (roomMaxY > maxY) maxY = roomMaxY

    // ヒットエリア生成
    const roomHa = new PIXI.Graphics()
    roomHa.beginFill(0x000000)
    roomHa.drawRect(minX, minY, maxX - minX, maxY - minY)
    roomHa.endFill()
    this.haContainer.addChild(roomHa)

    // 入力（PC）
    this.haContainer.on('mouseover', e => {
      this.onRollOver()
      this.emit(EVENT_TYPE.POINTER_ROLL_OVER)
    })
    this.haContainer.on('mouseout', e => {
      this.onRollOut()
      this.emit(EVENT_TYPE.POINTER_ROLL_OUT)
    })
    this.haContainer.on('click', e => {
      emitter.emit(EVENT_TYPE.PAGE_SELECT, this.model.action)
    })

    // 入力（SP）
    this.haContainer.on('touchstart', e => {
      // 時間とタッチ位置を保持
      const date = new Date()
      this.touchStartTime = date.getTime()
      this.touchStartPos = e.data.getLocalPosition(
        DataManager.getData('mapRoot')
      )
    })
    this.haContainer.on('touchend', e => {
      // 時間とタッチ位置で判定
      const date = new Date()
      const currentTime = date.getTime()
      const spendTime = currentTime - this.touchStartTime
      const currentPos = e.data.getLocalPosition(DataManager.getData('mapRoot'))
      const distance = Math.sqrt(
        Math.pow(this.touchStartPos.x - currentPos.x, 2) +
          Math.pow(this.touchStartPos.y - currentPos.y, 2)
      )
      if (distance <= 30 && spendTime <= 1000) {
        this.onRollOver()
        emitter.emit(EVENT_TYPE.ACTION_PAGE_PAUSE)
        TweenMax.delayedCall(1.0, () => {
          emitter.emit(EVENT_TYPE.PAGE_SELECT, this.model.action)
        })
      }
    })

    // リセット
    this.reset()
  }

  reset() {
    // 表示初期化
    TweenMax.killTweensOf(this.chara)
    TweenMax.set(this.chara, { alpha: 0 })
    TweenMax.killTweensOf(this.comment)
    TweenMax.set(this.comment, { alpha: 0 })
    TweenMax.killTweensOf(this.label)
    TweenMax.set(this.label, { alpha: 1 })
    TweenMax.killTweensOf(this.pointerContainer)
    TweenMax.set(this.pointerContainer, { alpha: 0, rotation: 0 })

    // 操作不可にする
    this.setEnabled(false)
  }

  setEnabled(bool) {
    this.haContainer.interactive = bool
  }

  // ---------------------------------------------------------------------------------------------------------
  // --	登場
  // ---------------------------------------------------------------------------------------------------------

  enter() {
    // ポインターの動き
    TweenMax.killTweensOf(this.pointerContainer)
    TweenMax.to(this.pointerContainer, 0.2, {
      y: -20,
      alpha: 1,
      rotation: -0.08,
      ease: 'power1.out',
      delay: this.model.enterDelay,
    })
    TweenMax.to(this.pointerContainer, 0.2, {
      y: 0,
      rotation: 0.0,
      ease: 'power1.in',
      delay: this.model.enterDelay + 0.3,
    })

    // Roomの動き
    TweenMax.killTweensOf(this.room)
    TweenMax.to(this.room, 0.2, {
      y: this.roomY - 16,
      ease: 'power1.out',
      delay: this.model.enterDelay,
    })
    TweenMax.to(this.room, 0.2, {
      y: this.roomY,
      ease: 'power1.in',
      delay: this.model.enterDelay + 0.3,
    })
    TweenMax.killTweensOf(this.room.scale)
    TweenMax.to(this.room.scale, 0.2, {
      x: 0.53,
      y: 0.53,
      ease: 'power1.out',
      delay: this.model.enterDelay,
    })
    TweenMax.to(this.room.scale, 0.2, {
      x: 0.5,
      y: 0.5,
      ease: 'power1.in',
      delay: this.model.enterDelay + 0.3,
    })

    // 操作可能にする
    TweenMax.delayedCall(this.model.enterDelay + 0.6, () => {
      this.setEnabled(true)
    })
  }

  // ---------------------------------------------------------------------------------------------------------
  // --	ホバーアクション
  // ---------------------------------------------------------------------------------------------------------

  onRollOver() {
    // TweenMax.killTweensOf(this)
    // TweenMax.to(this.scale, 0.1, { x: 1.1, y: 1.1 })

    // ポインターの動き
    TweenMax.killTweensOf(this.pointerContainer)
    TweenMax.to(this.pointerContainer, 0.2, {
      y: -20,
      ease: 'power1.out',
    })
    TweenMax.to(this.pointerContainer, 0.2, {
      y: 0,
      ease: 'power1.in',
      delay: 0.3,
    })

    // Roomの動き
    TweenMax.killTweensOf(this.room)
    TweenMax.to(this.room, 0.2, {
      y: this.roomY - 16,
      ease: 'power1.out',
    })
    TweenMax.to(this.room, 0.2, {
      y: this.roomY,
      ease: 'power1.in',
      delay: 0.3,
    })
    TweenMax.killTweensOf(this.room.scale)
    TweenMax.to(this.room.scale, 0.2, {
      x: 0.53,
      y: 0.53,
      ease: 'power1.out',
    })
    TweenMax.to(this.room.scale, 0.2, {
      x: 0.5,
      y: 0.5,
      ease: 'power1.in',
      delay: 0.3,
    })

    // ラベルとホバーの差し替え
    TweenMax.killTweensOf(this.chara)
    TweenMax.to(this.chara, 0.2, { alpha: 1, ease: 'none' })
    TweenMax.killTweensOf(this.comment)
    TweenMax.to(this.comment, 0.2, { alpha: 1, ease: 'none' })
    TweenMax.killTweensOf(this.label)
    TweenMax.to(this.label, 0.2, { alpha: 0, ease: 'none' })
  }

  onRollOut() {
    // ラベルとホバーの差し替え
    TweenMax.killTweensOf(this.chara)
    TweenMax.to(this.chara, 0.2, { alpha: 0, ease: 'none' })
    TweenMax.killTweensOf(this.comment)
    TweenMax.to(this.comment, 0.2, { alpha: 0, ease: 'none' })
    TweenMax.killTweensOf(this.label)
    TweenMax.to(this.label, 0.2, { alpha: 1, ease: 'none' })
  }

  // ---------------------------------------------------------------------------------------------------------
}

export default RoomPointerView
