import * as PageName from './PageName'

const POINTERS = new Map([
  [
    PageName.TOWN, // TOP
    [
      {
        name: 'park',
        position: {
          pc: { x: 698 + 80, y: 341.5 + 140 },
          sp: { x: 437 + 345, y: 632.5 - 127 },
        },
        room_pos: { x: 1310, y: 797 },
        room_anchor: { x: 132, y: 342 },
        enterDelay: 1,
        action: PageName.PARK,
      },
      {
        name: 'cafe',
        position: {
          pc: { x: 326 + 80, y: 489.5 + 140 },
          sp: { x: 64 + 345, y: 744.5 - 127 },
        },
        room_pos: { x: 616, y: 1227 },
        room_anchor: { x: 196.5, y: 308 },
        enterDelay: 0.2,
        action: PageName.CAFE,
      },
      {
        name: 'school',
        position: {
          pc: { x: 925 + 80, y: 262.5 + 140 },
          sp: { x: 657 + 345, y: 537.5 - 127 },
        },
        room_pos: { x: 1888, y: 502 },
        room_anchor: { x: 160, y: 340 },
        enterDelay: 0.6,
        action: PageName.SCHOOL,
      },
      // {
      //   name: 'bank',
      //   position: { x: 867.5 + 80, y: 473.5 + 140 },
      //   room_pos: { x: 1854, y: 1168 },
      //   room_anchor: { x: 160, y: 340 },
      //   enterDelay: 0.6,
      //   action: PageName.BANK,
      // },
      // {
      //   name: 'house',
      //   position: {
      //     pc: { x: 412 + 80, y: 236.5 + 140 },
      //     sp: { x: 147 + 345, y: 491.5 - 127 },
      //   },
      //   room_pos: { x: 616, y: 499 },
      //   room_anchor: { x: 218, y: 377 },
      //   enterDelay: 0.0,
      //   action: PageName.HOUSE,
      // },
      {
        name: 'house',
        position: {
          pc: { x: 1050, y: 600 },
          sp: { x: 1050, y: 600 },
        },
        room_pos: { x: 1805, y: 1140 },
        room_anchor: { x: 218, y: 377 },
        enterDelay: 0.0,
        action: PageName.HOUSE,
      },
      {
        name: 'award',
        position: {
          pc: { x: 463, y: 190 },
          sp: { x: 710, y: 355 },
        },
        enterDelay: 0.0,
        action: 'award',
      },
      // {
      //   name: 'schoo',
      //   position: {
      //     pc: { x: 95 + 80, y: 515 },
      //     sp: { x: 0 + 141, y: 655 - 127 },
      //   },
      //   enterDelay: 0.0,
      // },
      {
        name: 'myroom',
        position: {
          pc: { x: 160, y: 285 },
          sp: { x: 160, y: 285 },
        },
        enterDelay: 0.0,
        action: 'myroom',
      },
    ],
  ],
  [
    PageName.PARK, // 健活パーク
    [
      // 各ポインターを設置
      // position.x : デザインデータ左上からオブジェクトの中心点 + 80
      // position.y : デザインデータ左上からオブジェクトの中心点 + 140
      {
        name: 'park1',
        position: { x: 268 + 80, y: 440 + 140 },
        action: 'park1',
      },
      {
        name: 'park2',
        position: { x: 403 + 80, y: 235 + 140 },
        action: 'park2',
      },
    ],
  ],
  [
    PageName.CAFE,
    [
      {
        name: 'cafe1',
        position: { x: 282 + 80, y: 174 + 140 },
        action: 'cafe1',
      },
      {
        name: 'cafe2',
        position: { x: 720 + 80, y: 280 + 140 },
        action: 'cafe2',
      },
      {
        name: 'cafe3',
        position: { x: 495 + 80, y: 451 + 140 },
        action: 'cafe3',
      },
      {
        name: 'cafe4',
        position: { x: 863 + 80, y: 589 + 140 },
        action: 'cafe4',
      },
    ],
  ],
  [
    PageName.SCHOOL,
    [
      // {
      //   name: 'school1',
      //   position: { x: 458 + 80, y: 234 + 140 },
      //   action: 'school1',
      // },
      {
        name: 'school2',
        position: { x: 321 + 80, y: 377 + 140 },
        action: 'school2',
      },
    ],
  ],
  [
    PageName.BANK,
    [
      {
        name: 'bank1',
        position: { x: 384, y: 616 },
        action: 'bank1',
      },
      {
        name: 'bank2',
        position: { x: 867, y: 420 },
        action: 'bank2',
      },
    ],
  ],
  [
    PageName.HOUSE,
    [
      {
        name: 'house1',
        position: { x: 617 + 80, y: 217 + 140 },
        action: 'house1',
      },
    ],
  ],
])

export default POINTERS
