import * as PIXI from 'pixi.js-legacy'
import DataManager from './data/DataManager'
import AssetsManager from './data/AssetsManager'
import Transition from './transition/Transition'
import PixiDebug from './debug/PixiDebug'
import MapManager from './map/MapManger'
import AvatarView from './map/AvatarView'
import EditEffect from './effect/EditEffect'

export const transitionInitialize = async (debug = false) => {
  //DEBUG
  // if (debug) document.querySelector('#root').style.display = 'none'

  //ランディング用の画像を読み込んでから初期化
  try {
    await AssetsManager.loadLandingAssets()
  } catch (error) {
    console.log(`error:loadLandingAssets, ${error}`)
  }

  // front pixi initialize
  const front = new PIXI.Application({
    autoResize: true,
    resolution: devicePixelRatio,
    resizeTo: document.querySelector('#root'),
    view: document.querySelector('#front-container >canvas'),
    antialias: true,
    transparent: true,
  })
  DataManager.setData('front', front)

  //トランジション生成
  Transition.init()
}

export const mapInitialize = async data => {
  // react init時に発火
  // このタイミングで宝箱api通信かけて、データをもっとく
  // back pixi initialize
  const back = new PIXI.Application({
    autoResize: true,
    resolution: devicePixelRatio,
    resizeTo: document.querySelector('#root'),
    view: document.querySelector('#back-container >canvas'),
    backgroundColor: 0xfffaec,
  })
  DataManager.setData('back', back)

  //マップ用のコンテナ生成
  const mapRoot = new PIXI.Container()
  back.stage.addChild(mapRoot)
  DataManager.setData('mapRoot', mapRoot)

  //アバター生成
  const avatar = new AvatarView(data.avatarImages)
  DataManager.setData('avatar', avatar)

  //バナー用の画像をデータマネージャーで保持
  DataManager.setData('bannerImages', data.bannerImages)

  //Debugクラス生成
  // new PixiDebug()
}

export const chestInitialize = async data => {
  // BEからのデータを保持
  DataManager.setData('treasureChest', data.chestStatus)
}

// Map初期化
export const mapActionStart = async pageName => {
  //ページ登場
  await MapManager.pageEnter(pageName, true)
}

export const mapPageMove = async pageName => {
  await MapManager.pageMove(pageName)
}

export const mapPageEnter = () => MapManager.enter()

export const mapPagePause = () => MapManager.pause()

export const mapPageResume = () => MapManager.resume()

export const mapHidePointer = () => MapManager.hidePointer()

export const mapShowPointer = () => MapManager.showPointer()

export const wipeOut = async () => {
  await Transition.wipeOut()
}

export const wipeIn = async () => {
  await Transition.wipeIn()
}

export const showEffect = () => EditEffect.showEffect()

export const updateAvatar = images => {
  const avatar = DataManager.getData('avatar')
  if (avatar) {
    avatar.updateAvatar(images)
  }
}
