import React from 'react'
import PropTypes from 'prop-types'
import Shade from '../modal/Shade'

const Alert = ({ children }) => {
  return (
    <>
      <Shade />
      {children}
    </>
  )
}

Alert.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Alert
