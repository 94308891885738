import * as PageName from './PageName'

const ASSETS = new Map([
  [
    PageName.TOWN,
    [
      {
        type: 'bg',
        name: 'town-bg',
        src: '/img/map/town/bg@2x.jpg',
      },
      {
        type: 'spritesheet',
        name: 'town-assets-room',
        src: '/img/map/town/assets_room.json',
      },
      {
        type: 'spritesheet',
        name: 'town-room-house',
        src: '/img/map/town/room_house.json',
      },
      {
        type: 'spritesheet',
        name: 'myroom-pin',
        src: '/img/map/town/myroom_pin.json',
      },
      {
        device: 'sp',
        type: 'spritesheet',
        name: 'town-assets-sp',
        src: '/img/map/town/assets_sp.json',
      },
      {
        device: 'pc',
        type: 'spritesheet',
        name: 'town-assets-pc',
        src: '/img/map/town/assets_pc.json',
      },
      {
        type: 'spritesheet',
        name: 'town-animation',
        src: '/img/map/town/animation.json',
      },
      {
        type: 'cloud',
        name: 'town-cloud',
        src: '/img/map/town/town_cloud.png',
      },
      {
        type: 'spritesheet',
        name: 'award-pin',
        src: '/img/map/town/award_pin.json',
      },
      {
        type: 'spritesheet',
        name: 'award-pin-sp',
        src: '/img/map/town/award_pin_sp.json',
      },
      {
        type: 'spritesheet',
        name: 'schoo-pin',
        src: '/img/map/town/schoo_pin.json',
      },
    ],
  ],
  [
    PageName.PARK,
    [
      {
        type: 'bg',
        name: 'park-bg',
        src: '/img/map/park/bg@2x.jpg',
      },
      {
        type: 'spritesheet',
        name: 'park-animation-0',
        src: '/img/map/park/animation-0.json',
      },
      {
        type: 'spritesheet',
        name: 'park-animation-1',
        src: '/img/map/park/animation-1.json',
      },
      {
        type: 'spritesheet',
        name: 'park-animation-2',
        src: '/img/map/park/animation-2.json',
      },
    ],
  ],
  [
    PageName.CAFE,
    [
      {
        type: 'bg',
        name: 'cafe-bg',
        src: '/img/map/cafe/bg@2x.jpg',
      },
      {
        type: 'spritesheet',
        name: 'cafe-animation-0',
        src: '/img/map/cafe/animation-0.json',
      },
      {
        type: 'spritesheet',
        name: 'cafe-animation-1',
        src: '/img/map/cafe/animation-1.json',
      },
      {
        type: 'spritesheet',
        name: 'cafe-animation-2',
        src: '/img/map/cafe/animation-2.json',
      },
      {
        type: 'spritesheet',
        name: 'cafe-animation-3',
        src: '/img/map/cafe/animation-3.json',
      },
      {
        type: 'spritesheet',
        name: 'cafe-animation-4',
        src: '/img/map/cafe/animation-4.json',
      },
    ],
  ],
  [
    PageName.SCHOOL,
    [
      {
        type: 'bg',
        name: 'school-bg',
        src: '/img/map/school/bg@2x.jpg',
      },
      {
        type: 'spritesheet',
        name: 'school-animation-0',
        src: '/img/map/school/animation-0.json',
      },
      {
        type: 'spritesheet',
        name: 'school-animation-1',
        src: '/img/map/school/animation-1.json',
      },
      {
        type: 'spritesheet',
        name: 'school-animation-2',
        src: '/img/map/school/animation-2.json',
      },
      {
        type: 'spritesheet',
        name: 'school-animation-3',
        src: '/img/map/school/animation-3.json',
      },
      {
        type: 'spritesheet',
        name: 'school-animation-4',
        src: '/img/map/school/animation-4.json',
      },
      {
        type: 'spritesheet',
        name: 'school-animation-5',
        src: '/img/map/school/animation-5.json',
      },
    ],
  ],
  [
    PageName.BANK,
    [
      {
        type: 'bg',
        name: 'bank-bg',
        src: '/img/map/bank/bg@2x.jpg',
      },
    ],
  ],
  [
    PageName.HOUSE,
    [
      {
        type: 'bg',
        name: 'house-bg',
        src: '/img/map/house/bg@2x.jpg',
      },
      {
        type: 'spritesheet',
        name: 'house-animation-0',
        src: '/img/map/house/animation-0.json',
      },
      {
        type: 'spritesheet',
        name: 'house-animation-1',
        src: '/img/map/house/animation-1.json',
      },
    ],
  ],
])

export default ASSETS
