import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Point_help from '../../img/ui/Point_helpIcon.png'

const UserPoint = ({ onHelp, point, rank }) => {
  const rankClass = classNames('UserPoint', {
    '-bronze': rank === 0,
    '-silver': rank === 1,
    '-gold': rank === 2,
  })

  return (
    <div className={rankClass}>
      <div className="UserPoint_wrap">
        <b>{point}pt</b>

        <button onClick={onHelp} className="UserPoint_help">
          <img src={Point_help} alt="Pointとは？" />
        </button>
      </div>
    </div>
  )
}

UserPoint.propTypes = {
  point: PropTypes.number.isRequired,
  rank: PropTypes.number.isRequired,
  onHelp: PropTypes.func.isRequired,
}

export default UserPoint
