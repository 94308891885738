import React from 'react'
import PropTypes from 'prop-types'

import ModalCloseX from '../../img/modal/Modal_Btn__close.png'

const ButtonCloseX = ({ onClose }) => {
  return (
    <div className="ModalButton -closemin">
      <button onClick={_ => onClose()}>
        <img src={ModalCloseX} alt="閉じる" />
      </button>
    </div>
  )
}

ButtonCloseX.propTypes = {
  onClose: PropTypes.func.isRequired,
}

export default ButtonCloseX
