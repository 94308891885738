export const TOWN = Symbol('PageNameTown')
export const PARK = Symbol('PageNamePark')
export const CAFE = Symbol('PageNameCafe')
export const BANK = Symbol('PageNameBank')
export const HOUSE = Symbol('PageNameHouse')
export const SCHOOL = Symbol('PageNameSchool')
export const AWARD = Symbol('PageNameAward')

export const toPagename = symbol => {
  switch (symbol) {
    case TOWN:
      return 'town'
    case PARK:
      return 'park'
    case CAFE:
      return 'cafe'
    case BANK:
      return 'bank'
    case HOUSE:
      return 'house'
    case SCHOOL:
      return 'school'
    case AWARD:
      return 'award'
    default:
      return null
  }
}

export const toPathname = symbol => {
  switch (symbol) {
    case TOWN:
      return '/'
    case PARK:
      return '/park'
    case CAFE:
      return '/cafe'
    case BANK:
      return '/bank'
    case HOUSE:
      return '/house'
    case SCHOOL:
      return '/school'
    case AWARD:
      return '/award'
    default:
      return null
  }
}

export const toPagenameFromPath = path => {
  switch (path) {
    case '/':
    case '/news':
      return 'town'
    case '/park':
      return 'park'
    case '/cafe':
      return 'cafe'
    case '/bank':
      return 'bank'
    case '/house':
      return 'house'
    case '/school':
      return 'school'
    case '/award':
      return 'award'
    default:
      return null
  }
}

export const toSymbolFromPath = path => {
  const roomRe = /\/(.+?)(\/(.+?))?$/
  if (path === '/' || path === '/news') {
    return TOWN
  }
  if (roomRe.test(path)) {
    const res = roomRe.exec(path)
    switch (res[0]) {
      case '/park':
        return PARK
      case '/cafe':
        return CAFE
      case '/bank':
        return BANK
      case '/house':
        return HOUSE
      case '/school':
        return SCHOOL
      case '/award':
        return AWARD
    }
  }

  return null
}

export const toSymbol = path => {
  switch (path) {
    case 'town':
      return TOWN
    case 'park':
      return PARK
    case 'cafe':
      return CAFE
    case 'bank':
      return BANK
    case 'house':
      return HOUSE
    case 'school':
      return SCHOOL
    case 'award':
      return AWARD
    default:
      return null
  }
}

const PageName = {
  toPagename,
  toPathname,
  toPagenameFromPath,
  toSymbol,
  toSymbolFromPath,
}

export default PageName
