import * as PIXI from 'pixi.js-legacy'
import { TweenMax } from 'gsap/gsap-core'
import AssetsManager from '../data/AssetsManager'

/**
 * EditEffectクラス
 */
class EditEffect {
  // ---------------------------------------------------------------------------------------------------------
  // --	EditEffect
  // ---------------------------------------------------------------------------------------------------------

  /**
   * コンストラクタ
   */
  constructor() {
    // pixi initialize
    this.pixi = new PIXI.Application({
      autoResize: true,
      resolution: devicePixelRatio,
      antialias: true,
      transparent: true,
    })
    this.pixi.view.style.position = 'absolute'
    this.pixi.view.style.zIndex = 10
    this.pixi.view.style.pointerEvents = 'none'
  }

  // ---------------------------------------------------------------------------------------------------------

  /**
   * 演出発動
   */
  showEffect() {
    //DOMにCanvasを配置
    const dom = document.querySelector('.CharaArea')
    if (!dom) return
    // console.log(dom)
    this.pixi.resizeTo = dom
    dom.appendChild(this.pixi.view)
    this.pixi.resize()

    //スクリーンサイズ
    const screenWidth = this.pixi.renderer.width / this.pixi.renderer.resolution
    const screenHeight =
      this.pixi.renderer.height / this.pixi.renderer.resolution

    //星を生成
    const starNum = 8
    const rotUnit = 360 / starNum
    for (let i = 0; i < starNum; i++) {
      this.createStar(
        i,
        rotUnit,
        new PIXI.Point(screenWidth / 2 - 10, screenHeight / 2 + 30)
      )
    }
  }

  createStar(index, rotUnit, basePos) {
    //サイズ
    const startSize = 0.05 + (Math.random() * 150) / 1000
    const endSize = startSize * 1.5

    //拡散角度
    const baseRot =
      rotUnit * index - rotUnit / 4 + Math.random() * (rotUnit / 2)
    const baseRad = baseRot * (Math.PI / 180)

    //スタート位置
    const startDistance = 10 + (Math.random() * 400) / 10
    const startX = basePos.x + startDistance * Math.cos(baseRad)
    const startY =
      basePos.y +
      startDistance * Math.sin(baseRad) * 2.0 * (1 - Math.cos(baseRad))

    //拡散後の位置
    const endDistance = 60 + (Math.random() * 400) / 10
    const endX = startX + endDistance * Math.cos(baseRad)
    const endY = startY + endDistance * Math.sin(baseRad)

    //回転速度
    let rotSpeed = (1 + Math.random() * 2) * (Math.PI / 180)
    if (Math.random() * 100 < 50) rotSpeed *= -1

    //星生成
    const star = AssetsManager.getSpriteByName('star.png')
    star.anchor.set(0.5, 0.5)
    star.scale.set(startSize, startSize)
    star.x = startX
    star.y = startY
    star.rotation = Math.random() * 360 * (Math.PI / 180)
    this.pixi.stage.addChild(star)

    //アニメーション
    TweenMax.to(star, 0.9, {
      x: endX,
      y: endY,
      ease: 'power3.out',
      onUpdate: () => {
        star.rotation += rotSpeed
      },
    })
    TweenMax.to(star.scale, 0.9, { x: endSize, y: endSize })
    TweenMax.to(star, 0.2, {
      alpha: 0.0,
      ease: 'none',
      delay: 0.7,
      onComplete: () => {
        star.parent.removeChild(star)
      },
    })
  }

  // ---------------------------------------------------------------------------------------------------------
}

const instance = new EditEffect()
export default instance
