import React, { useContext, useState, useCallback } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { animated, useTransition } from 'react-spring'
import img_btnClose from '../../img/ui/Avatar_btn__close.png'
import { Context } from '../../Context'

const TopAvatar = ({ pathname }) => {
  const { state } = useContext(Context)
  const [isBalloon, setBalloon] = useState(true)

  const { userStatus, avatarPath } = state.userData

  const onClose = useCallback(() => {
    setBalloon(false)
  }, [])

  const transition = useTransition(isBalloon, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  })

  const paths = ['/', '/news']

  return (
    <div className="TopAvatarArea">
      {paths.includes(pathname) && (
        <div className="TopAvatarArea_wrap">
          <div className="TopAvatar">
            <img src={avatarPath.open} alt="" />
          </div>
          {transition.map(
            ({ item, props, key }) =>
              item && (
                <animated.div
                  key={key}
                  style={props}
                  className="TopAvatarBalloon"
                >
                  <div className="TopAvatarBalloon_wrap">
                    <div className="__Speech">
                      <p
                        dangerouslySetInnerHTML={{
                          __html: userStatus.avatarText,
                        }}
                      />
                      {userStatus.avatarLink ? (
                        <Link to={userStatus.avatarLink}>
                          <img
                            src={userStatus.avatarButton}
                            alt="FPに相談する"
                          />
                        </Link>
                      ) : null}
                    </div>

                    <button onClick={_ => onClose()} className="__close">
                      <img src={img_btnClose} alt="" />
                    </button>
                  </div>
                </animated.div>
              )
          )}
        </div>
      )}
    </div>
  )
}

TopAvatar.propTypes = {
  pathname: PropTypes.string.isRequired,
}

export default TopAvatar
