import React, { useReducer } from 'react'
import PropTypes from 'prop-types'
import { ActionTypes } from './constants'

/**
 * 初期値
 */
const initialState = {
  showedAvatarHelp: localStorage.getItem('showedAvatarHelp') || false,
}

/**
 * Reducer
 */
const reducer = (state, action) => {
  switch (action.type) {
    case ActionTypes.showedAvatarHelp: {
      localStorage.setItem('showedAvatarHelp', true)
      return {
        ...state,
        showedAvatarHelp: true,
      }
    }
    default: {
      return state
    }
  }
}

const AvatarContext = React.createContext(initialState)

const AvatarContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  return (
    <AvatarContext.Provider value={{ state, dispatch }}>
      {children}
    </AvatarContext.Provider>
  )
}

export { AvatarContext, AvatarContextProvider }

AvatarContextProvider.propTypes = {
  children: PropTypes.element.isRequired,
}
