import React from 'react'
import PropTypes from 'prop-types'

import Logo from '../img/common/Logo.png'

const LogoMytown = ({ pathname }) => {
  const paths = ['/', '/news']
  return (
    <h1 className="LogoMytown">
      {paths.includes(pathname) && <img src={Logo} alt="マイタウン" />}
    </h1>
  )
}

LogoMytown.propTypes = {
  pathname: PropTypes.string.isRequired,
}

export default LogoMytown
