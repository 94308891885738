import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import { getDevice } from './utils/deviceDetect'
import App from './App'
import { ContextProvider } from './Context'
import { AvatarContextProvider } from './AvatarContext'

async function initialize() {
  const device = getDevice()
  document.body.classList.add(device)
  ReactDOM.render(
    <Router>
      <ContextProvider>
        <AvatarContextProvider>
          <Suspense fallback={<div />}>
            <App device={device} />
          </Suspense>
        </AvatarContextProvider>
      </ContextProvider>
    </Router>,
    document.getElementById('root')
  )
}

initialize()
