import React from 'react'
import PropTypes from 'prop-types'
import { animated } from 'react-spring'
import { useLocation } from 'react-router-dom'

import Shade from '../modal/Shade'
import ButtonClose from '../modal/ButtonClose'
import ButtonCloseX from '../modal/ButtonCloseX'

import IconLogin from '../../img/modal/Icon_login.png'
import ModalTitle from '../../img/modal/Modal_title__login.png'

import IconLine from '../../img/avatar/Icon_line.png'
import IconFacebook from '../../img/avatar/Icon_fb.png'
import IconTwitter from '../../img/avatar/Icon_tw.png'
import BtnLine from '../../img/avatar/Btn_line.png'
import BtnFacebook from '../../img/avatar/Btn_fb.png'
import BtnTwitter from '../../img/avatar/Btn_tw.png'

import BtnLine_sp from '../../img/avatar/Btn_line__sp.png'
import BtnFacebook_sp from '../../img/avatar/Btn_fb__sp.png'
import BtnTwitter_sp from '../../img/avatar/Btn_tw__sp.png'
import useModalIntro from '../../hooks/useModalIntro'

const getAuthUrl = sns =>
  `${process.env.REACT_APP_SOCIAL_PLUS_BASE_URL}/${sns}/authenticate?callback=${process.env.REACT_APP_SOCIAL_PLUS_CALLBACK_URL}`
const getLoginUrl = sns =>
  `${process.env.REACT_APP_SOCIAL_PLUS_BASE_URL}/${sns}/authenticate?callback=${process.env.REACT_APP_SOCIAL_PLUS_LOGIN_URL}`
const getMyroomUrl = sns =>
  `${process.env.REACT_APP_SOCIAL_PLUS_BASE_URL}/${sns}/authenticate?callback=${process.env.REACT_APP_SOCIAL_PLUS_MYROOM_URL}`

const Login = ({ onClose, isChestClick }) => {
  const location = useLocation()
  const props = useModalIntro()
  const isCreate = location.pathname === '/avatar'
  const isMyRoom = location.pathname === '/myroom'

  return (
    <>
      <Shade />
      {isChestClick ? (
        <>
          <section className="ModalLogin">
            <animated.div style={props} className="_Modal -type_1">
              <div className="__wrap">
                <h1 className="__title typeText">
                  <span className="img">
                    <img src={IconLogin} alt="" />
                  </span>
                  簡単ログインでポイントを貯めよう！
                  <br />
                  ログイン連携するSNSを選択してね
                </h1>
                <ul className="ModalLogin_sns">
                  <li className="__item">
                    <i>
                      <img src={IconLine} alt="" />
                    </i>
                    <a
                      href={isCreate ? getAuthUrl('line') : getLoginUrl('line')}
                    >
                      <img src={BtnLine} alt="Line" className="-pc" />
                      <img src={BtnLine_sp} alt="Line" className="-sp" />
                    </a>
                  </li>
                  {/* <li className="__item">
                    <i>
                      <img src={IconFacebook} alt="" />
                    </i>
                    <a
                      href={
                        isCreate
                          ? getAuthUrl('facebook')
                          : getLoginUrl('facebook')
                      }
                    >
                      <img src={BtnFacebook} alt="Facebook" className="-pc" />
                      <img
                        src={BtnFacebook_sp}
                        alt="Facebook"
                        className="-sp"
                      />
                    </a>
                  </li> */}
                  <li className="__item">
                    <i>
                      <img src={IconTwitter} alt="" />
                    </i>
                    <a
                      href={
                        isCreate
                          ? getAuthUrl('twitter')
                          : getLoginUrl('twitter')
                      }
                    >
                      <img src={BtnTwitter} alt="Twitter" className="-pc" />
                      <img src={BtnTwitter_sp} alt="Twitter" className="-sp" />
                    </a>
                  </li>
                </ul>

                <p className="ModalLogin_text">
                  マイタウンポイントを貯めるには各種SNSとの連携が必要です。ログインする度にポイントが貯まったり、貯まったポイントで新しいパーツをゲットできるよ。
                  <br />
                  ※各ソーシャルアカウントのログイン画面へ移動します。
                </p>
              </div>
            </animated.div>

            <ButtonClose onClose={onClose} />
            <ButtonCloseX onClose={onClose} />
          </section>
        </>
      ) : (
        <>
          <section className="ModalLogin">
            <animated.div style={props} className="_Modal -type_1">
              {isMyRoom ? (
                <div className="__wrap">
                  <h1 className="__title">
                    <img src={ModalTitle} alt="ログイン連携するSNSを選択" />
                  </h1>
                  <ul className="ModalLogin_sns">
                    <li className="__item">
                      <i>
                        <img src={IconLine} alt="" />
                      </i>
                      <a href={getMyroomUrl('line')}>
                        <img src={BtnLine} alt="Line" className="-pc" />
                        <img src={BtnLine_sp} alt="Line" className="-sp" />
                      </a>
                    </li>
                    {/* <li className="__item">
                      <i>
                        <img src={IconFacebook} alt="" />
                      </i>
                      <a href={getMyroomUrl('facebook')}>
                        <img src={BtnFacebook} alt="Facebook" className="-pc" />
                        <img
                          src={BtnFacebook_sp}
                          alt="Facebook"
                          className="-sp"
                        />
                      </a>
                    </li> */}
                    <li className="__item">
                      <i>
                        <img src={IconTwitter} alt="" />
                      </i>
                      <a href={getMyroomUrl('twitter')}>
                        <img src={BtnTwitter} alt="Twitter" className="-pc" />
                        <img
                          src={BtnTwitter_sp}
                          alt="Twitter"
                          className="-sp"
                        />
                      </a>
                    </li>
                  </ul>
                  <p className="ModalLogin_text">
                    マイタウンをもっと楽しむなら、各種SNSと連携しよう！
                    <br />
                    次回ログイン時にもキャラクターを引き継げる保存機能に加えて、今後スタート予定のログインボーナスもゲットできます。
                    <br />
                    ※各ソーシャルアカウントのログイン画面へ移動します
                  </p>
                  <p className="ModalLogin_annotation">
                    2023年3月28日より、FacebookでのSNSログインがなくなり、LINEまたはX（旧Twitter）のみの対応となりました。
                    <br />
                    申し訳ございませんが、FacebookでSNSログインをされていた方は、LINEまたはX（旧Twitter）にてご利用いただきますようお願いいたします。
                    <br />
                    システムの都合上、Facebookログインでのアバターデータ引き継ぎはできませんのでご了承いただけますようお願い申し上げます。
                  </p>
                </div>
              ) : (
                <div className="__wrap">
                  <h1 className="__title">
                    <img src={ModalTitle} alt="ログイン連携するSNSを選択" />
                  </h1>
                  <ul className="ModalLogin_sns">
                    <li className="__item">
                      <i>
                        <img src={IconLine} alt="" />
                      </i>
                      <a
                        href={
                          isCreate ? getAuthUrl('line') : getLoginUrl('line')
                        }
                      >
                        <img src={BtnLine} alt="Line" className="-pc" />
                        <img src={BtnLine_sp} alt="Line" className="-sp" />
                      </a>
                    </li>
                    {/* <li className="__item">
                      <i>
                        <img src={IconFacebook} alt="" />
                      </i>
                      <a
                        href={
                          isCreate
                            ? getAuthUrl('facebook')
                            : getLoginUrl('facebook')
                        }
                      >
                        <img src={BtnFacebook} alt="Facebook" className="-pc" />
                        <img
                          src={BtnFacebook_sp}
                          alt="Facebook"
                          className="-sp"
                        />
                      </a>
                    </li> */}
                    <li className="__item">
                      <i>
                        <img src={IconTwitter} alt="" />
                      </i>
                      <a
                        href={
                          isCreate
                            ? getAuthUrl('twitter')
                            : getLoginUrl('twitter')
                        }
                      >
                        <img src={BtnTwitter} alt="Twitter" className="-pc" />
                        <img
                          src={BtnTwitter_sp}
                          alt="Twitter"
                          className="-sp"
                        />
                      </a>
                    </li>
                  </ul>

                  <p className="ModalLogin_text">
                    マイタウンをもっと楽しむなら、各種SNSと連携しよう！
                    <br />
                    次回ログイン時にもキャラクターを引き継げる保存機能に加えて、今後スタート予定のログインボーナスもゲットできます。
                    <br />
                    ※各ソーシャルアカウントのログイン画面へ移動します
                  </p>
                  <p className="ModalLogin_annotation">
                    2023年3月28日より、FacebookでのSNSログインがなくなり、LINEまたはX（旧Twitter）のみの対応となりました。
                    <br />
                    申し訳ございませんが、FacebookでSNSログインをされていた方は、LINEまたはX（旧Twitter）にてご利用いただきますようお願いいたします。
                    <br />
                    システムの都合上、Facebookログインでのアバターデータ引き継ぎはできませんのでご了承いただけますようお願い申し上げます。
                  </p>
                </div>
              )}
            </animated.div>

            <ButtonClose onClose={onClose} />
            <ButtonCloseX onClose={onClose} />
          </section>
        </>
      )}
    </>
  )
}

Login.propTypes = {
  onClose: PropTypes.func.isRequired,
  isChestClick: PropTypes.bool.isRequired,
}

export default Login
