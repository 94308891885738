import React from 'react'
import PropTypes from 'prop-types'
import MoveNext from '../../img/ui/Icon_arrow01.png'

const Arrows = ({ length, current, onNext, onPrev }) => {
  return (
    <ul
      style={{ display: length === 1 ? 'none' : 'flex' }}
      className="ModalArea_move"
    >
      <li>
        <button
          style={{ visibility: current > 0 ? 'visible' : 'hidden' }}
          onClick={_ => onPrev()}
          className="__back"
        >
          <img src={MoveNext} alt="前へ" />
        </button>
      </li>
      <li>
        <button
          style={{
            visibility: current < length - 1 ? 'visible' : 'hidden',
          }}
          onClick={_ => onNext()}
          className="__next"
        >
          <img src={MoveNext} alt="次へ" />
        </button>
      </li>
    </ul>
  )
}

Arrows.propTypes = {
  current: PropTypes.number.isRequired,
  length: PropTypes.number.isRequired,
  onNext: PropTypes.func.isRequired,
  onPrev: PropTypes.func.isRequired,
}

export default Arrows
