import * as PIXI from 'pixi.js-legacy'
import { TweenMax } from 'gsap'
import emitter, { EVENT_TYPE } from '../../events'
import { getDevice } from '../../utils/deviceDetect'
import AssetsManager from '../data/AssetsManager'
import DataManager from '../data/DataManager'

/**
 * PointerViewクラス
 */
class PointerView extends PIXI.Container {
  // ---------------------------------------------------------------------------------------------------------
  // --	PointerView
  // ---------------------------------------------------------------------------------------------------------

  /**
   * コンストラクタ
   */
  constructor(model) {
    super()

    // モデルを保持
    this.model = model

    // ループTween
    this.loopTween = null

    // SP版のクリック判定用変数
    this.touchStartPos = new PIXI.Point()
    this.touchStartTime = 0

    // ポインター配置
    this.pointer = AssetsManager.getSpriteByName(
      `pointer_${this.model.name}.png`
    )
    this.pointer.anchor.set(0.5, 0.5)
    this.pointer.scale.set(0.5, 0.5)
    this.addChild(this.pointer)

    // ヒットエリア
    this.ha = new PIXI.Graphics()
    this.ha.beginFill(0x000000)
    this.ha.drawRect(-135, -42, 270, 84)
    this.ha.endFill()
    this.ha.alpha = 0.0
    this.ha.buttonMode = true
    this.ha.interactive = true
    this.addChild(this.ha)

    // ホバーの設定（PCのみ）
    const device = getDevice()
    if (device === 'pc') {
      this.ha.on('mouseover', e => {
        this.onRollOver()
        this.emit(EVENT_TYPE.POINTER_ROLL_OVER)
      })
      this.ha.on('mouseout', e => {
        this.onRollOut()
        this.emit(EVENT_TYPE.POINTER_ROLL_OUT)
      })
    }

    // クリックアクション
    this.ha.on('click', e => {
      emitter.emit(EVENT_TYPE.POINTER_SELECT, this.model.action)
    })

    // 入力（SP）
    this.ha.on('touchstart', e => {
      // 時間とタッチ位置を保持
      const date = new Date()
      this.touchStartTime = date.getTime()
      this.touchStartPos = e.data.getLocalPosition(
        DataManager.getData('mapRoot')
      )

      // ロールオーバー
      this.onRollOver()
      this.emit(EVENT_TYPE.POINTER_ROLL_OVER)
    })
    this.ha.on('touchend', e => {
      // 時間とタッチ位置で判定
      const date = new Date()
      const currentTime = date.getTime()
      const spendTime = currentTime - this.touchStartTime
      const currentPos = e.data.getLocalPosition(DataManager.getData('mapRoot'))
      const distance = Math.sqrt(
        Math.pow(this.touchStartPos.x - currentPos.x, 2) +
          Math.pow(this.touchStartPos.y - currentPos.y, 2)
      )

      // クリック判定
      if (distance <= 30 && spendTime <= 1000) {
        emitter.emit(EVENT_TYPE.POINTER_SELECT, this.model.action)
      }

      // ロールアウト
      this.onRollOut()
      this.emit(EVENT_TYPE.POINTER_ROLL_OUT)
    })

    // リセット
    this.reset()
  }

  reset() {
    // ループ停止
    this.loopTween = null

    // 非表示
    TweenMax.killTweensOf(this)
    TweenMax.set(this, { alpha: 0 })

    // 操作不可にする
    this.setEnabled(false)

    // ロールアウト
    this.onRollOut()
  }

  setEnabled(bool) {
    this.ha.interactive = bool
  }

  // ---------------------------------------------------------------------------------------------------------
  // --	登場
  // ---------------------------------------------------------------------------------------------------------

  enter() {
    // 初期表示
    TweenMax.killTweensOf(this)
    TweenMax.set(this, {
      alpha: 0,
      x: this.model.position.x,
      y: this.model.position.y - 10,
    })

    // 登場アニメーション
    TweenMax.to(this, 0.6, { alpha: 1, ease: 'none' })
    TweenMax.to(this, 0.6, {
      y: this.model.position.y + 20,
      ease: 'power1.out',
    })
    TweenMax.to(this, 0.5, {
      y: this.model.position.y,
      ease: 'power1.in',
      delay: 0.6,
    })
    TweenMax.to(this, 0.5, {
      y: this.model.position.y + 20,
      ease: 'power1.out',
      delay: 1.1,
    })
    TweenMax.to(this, 0.5, {
      y: this.model.position.y,
      ease: 'power1.in',
      delay: 1.6,
      onComplete: () => {
        this.setEnabled(true)
        this.loopTween = TweenMax.to(this, 2.0, {
          onComplete: () => {
            this.loopAnimation()
          },
        })
      },
    })
  }

  // ---------------------------------------------------------------------------------------------------------
  // --	ループアニメーション
  // ---------------------------------------------------------------------------------------------------------
  loopAnimation() {
    // アニメーション
    if (this.ha.interactive) {
      TweenMax.to(this, 0.6, {
        y: this.model.position.y - 20,
        ease: 'back.in(1.6)',
        onComplete: () => {
          TweenMax.to(this, 0.6, {
            y: this.model.position.y,
            ease: 'back.out(1.6)',
          })
        },
      })
    }

    // ループ
    this.loopTween = TweenMax.to(this, 3.0, {
      onComplete: () => {
        this.loopAnimation()
      },
    })
  }

  // ---------------------------------------------------------------------------------------------------------
  // --	ホバーアクション
  // ---------------------------------------------------------------------------------------------------------

  onRollOver() {
    TweenMax.killTweensOf(this.scale)
    TweenMax.to(this.scale, 0.1, { x: 1.1, y: 1.1 })
  }

  onRollOut() {
    TweenMax.killTweensOf(this.scale)
    TweenMax.to(this.scale, 0.3, { x: 1.0, y: 1.0 })
  }

  // ---------------------------------------------------------------------------------------------------------
}

export default PointerView
