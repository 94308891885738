import React, { useContext, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { animated, useTransition } from 'react-spring'

import { Link } from 'react-router-dom'
import PageNavi_ttl from '../../img/ui/PageNavi_ttl.png'
import PageNavi_btn_house from '../../img/ui/PageNavi_btn_house.png'
import PageNavi_btn_school from '../../img/ui/PageNavi_btn_school.png'
import PageNavi_btn_park from '../../img/ui/PageNavi_btn_park.png'
import PageNavi_btn_cafe from '../../img/ui/PageNavi_btn_cafe.png'
import { Context } from '../../Context'

const PageNavigation = ({ pathname }) => {
  const paths = ['/school', '/cafe', '/house', '/park']
  let currentPath = pathname
  return (
    <div className="PageNavigation">
      {paths.includes(pathname) && (
        <>
          <p className="__Title">
            <img src={PageNavi_ttl} alt="他のエリア" />
          </p>
          <ul className="__List">
            {currentPath !== '/school' && (
              <li>
                <Link to="/school">
                  <img src={PageNavi_btn_school} alt="" />
                </Link>
              </li>
            )}
            {currentPath !== '/cafe' && (
              <li>
                <Link to="/cafe">
                  <img src={PageNavi_btn_cafe} alt="" />
                </Link>
              </li>
            )}
            {currentPath !== '/house' && (
              <li>
                <Link to="/house">
                  <img src={PageNavi_btn_house} alt="" />
                </Link>
              </li>
            )}
            {currentPath !== '/park' && (
              <li>
                <Link to="/park">
                  <img src={PageNavi_btn_park} alt="" />
                </Link>
              </li>
            )}
          </ul>
        </>
      )}
    </div>
  )
}

PageNavigation.propTypes = {
  pathname: PropTypes.string.isRequired,
}

export default PageNavigation
