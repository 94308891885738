import React, { useContext } from 'react'
import { Context } from '../../Context'

const UserStatus = () => {
  const { state } = useContext(Context)

  const { userStatus } = state.userData

  return (
    <div className="UserStatus">
      <div className="UserStatus_img">
        <img src={userStatus.statusImage} alt="" />
      </div>
    </div>
  )
}

export default UserStatus
