import * as PIXI from 'pixi.js-legacy'
import emitter, { EVENT_TYPE } from '../../events'
import DataManager from './DataManager'
import ASSETS from '../constants/Assets'
import * as PageName from '../constants/PageName'
import { getDevice } from '../../utils/deviceDetect'

/**
 * アセット管理用クラス
 */
class AssetsMananger {
  // ---------------------------------------------------------------------------------------------------------
  // --	AssetsMananger
  // ---------------------------------------------------------------------------------------------------------

  /**
   * コンストラクタ
   */
  constructor() {
    this.resourcesMap = new Map()
    this.commonIsLoaded = false
  }

  // ---------------------------------------------------------------------------------------------------------
  // --	読み込み
  // ---------------------------------------------------------------------------------------------------------

  /**
   * Landing用の画像読み込み
   */
  loadLandingAssets() {
    return new Promise((resolve, reject) => {
      //ローダー生成
      const loader = new PIXI.Loader()
      loader.add('landing', '/img/common/landing.json', {
        crossOrigin: 'anonymous',
      })
      loader.add('map-common', '/img/map/common/parts.json', {
        crossOrigin: 'anonymous',
      })
      loader.add('map-pointer', '/img/map/common/pointer.json', {
        crossOrigin: 'anonymous',
      })
      loader.add('map-treasure-chest', '/img/map/common/treasure-chest.json', {
        crossOrigin: 'anonymous',
      })

      //エラーのハンドリング
      loader.onError.add(e => {
        console.log(e)
        reject('画像読み込み失敗')
      })

      //読み込み
      loader.load((loader, resources) => {
        // console.log(resources)
        resolve()
      })
    })
  }

  /**
   * ページのAssets読み込み
   */
  loadAssets(pageName, showLoading = true) {
    return new Promise((resolve, reject) => {
      //ロード済みならすぐに解決
      if (this.resourcesMap.has(pageName)) {
        resolve()
        return
      }

      //ローディング表示
      if (showLoading) {
        emitter.emit(EVENT_TYPE.ACTION_SHOW_LOADING)
        // console.log('Loading表示')
      }

      //ローダー生成
      const loader = new PIXI.Loader()

      //assets参照
      const assets = ASSETS.get(pageName)

      //共通素材の読み込み準備
      if (!this.commonIsLoaded) {
        this.commonIsLoaded = true
        loader.add('loading', '/img/common/loading.json', {
          crossOrigin: 'anonymous',
        })
      }

      //読み込み準備
      for (let i = 0; i < assets.length; i++) {
        const asset = assets[i]
        if (!asset.device || (asset.device && asset.device === getDevice())) {
          loader.add(asset.name, asset.src, {
            crossOrigin: 'anonymous',
          })
        }
      }

      //エラーのハンドリング
      loader.onError.add(() => {
        console.log('onError')
        reject('画像読み込み失敗')
      })

      //読み込み
      emitter.emit(EVENT_TYPE.LOAD_START)
      loader.load((loader, resources) => {
        // console.log(resources)
        this.resourcesMap.set(pageName, resources)
        emitter.emit(EVENT_TYPE.LOAD_COMPLETE)
        resolve()
      })
    })
  }

  /**
   * Banner用の画像読み込み
   */
  loadBannerAssets() {
    return new Promise((resolve, reject) => {
      //バナーのImage配列
      const bannerImages = DataManager.getData('bannerImages')

      //ローダー生成
      const loader = new PIXI.Loader()

      //読み込み準備
      for (let i = 0; i < bannerImages.length; i++) {
        const image = bannerImages[i]
        loader.add('banner' + i, image.src, {
          crossOrigin: 'anonymous',
        })
      }

      //エラーのハンドリング
      loader.onError.add(() => {
        reject('画像読み込み失敗')
      })

      //読み込み
      loader.load((loader, resources) => {
        const keys = Object.keys(resources)
        let banners = []
        for (let i = 0; i < keys.length; i++) {
          banners.push(new PIXI.Sprite.from(keys[i]))
        }
        resolve(banners)
      })
    })
  }

  // ---------------------------------------------------------------------------------------------------------
  // --	アバター生成
  // ---------------------------------------------------------------------------------------------------------

  /**
   * Avatar生成
   */
  createAvatar(images) {
    return new Promise((resolve, reject) => {
      //ローダー生成
      const loader = new PIXI.Loader()

      //読み込み準備
      for (let i = 0; i < images.length; i++) {
        const image = images[i]
        loader.add(image.src, {
          crossOrigin: 'anonymous',
        })
      }

      //エラーのハンドリング
      loader.onError.add(() => {
        reject('画像読み込み失敗')
      })

      //読み込み
      loader.load((loader, resources) => {
        const textureArray = []
        for (let i = 0; i < images.length; i++) {
          let texture = PIXI.Texture.from(images[i].src)
          textureArray.push(texture)
        }
        resolve(new PIXI.AnimatedSprite(textureArray))
      })
    })
  }

  // ---------------------------------------------------------------------------------------------------------
  // --	生成
  // ---------------------------------------------------------------------------------------------------------

  /**
   * 背景スプライト生成
   */
  getBg(pageName) {
    const assets = ASSETS.get(pageName)
    for (let i = 0; i < assets.length; i++) {
      const asset = assets[i]
      if (asset.type === 'bg') {
        return new PIXI.Sprite.from(asset.name)
      }
    }
    return null
  }

  /**
   * アイテム生成
   */
  getItem(model) {
    let item
    switch (model.type) {
      case 'animation':
        item = this.getAnimation(model)
        break
      case 'sprite':
        item = this.getSprite(model)
        break
      default:
        item = null
    }

    if (!item) return null

    item.scale.set(0.5, 0.5)
    item.model = model

    return item
  }

  /**
   * アニメーション生成
   */
  getAnimation(model) {
    //フレーム名の配列取得
    let frames = []
    for (let i = 0; i < model.length; i++) {
      let fname = model.name + '_00'
      if (i < 10) {
        fname += '00' + i
      } else if (i < 100) {
        fname += '0' + i
      } else {
        fname += i
      }
      fname += '.png'
      frames.push(fname)
    }

    //逆再生
    if (model.reverse) {
      for (let i = model.length - 1; i >= 0; i--) {
        let fname = model.name + '_00'
        if (i < 10) {
          fname += '00' + i
        } else if (i < 100) {
          fname += '0' + i
        } else {
          fname += i
        }
        fname += '.png'
        frames.push(fname)
      }
    }

    return new PIXI.AnimatedSprite.fromFrames(frames)
  }

  /**
   * スプライト生成
   */
  getSprite(model) {
    return new PIXI.Sprite.from(model.name)
  }

  /**
   * 名前からスプライト生成
   */
  getSpriteByName(name) {
    return new PIXI.Sprite.from(name)
  }

  // ---------------------------------------------------------------------------------------------------------
}

export default new AssetsMananger()
