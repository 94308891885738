import * as PIXI from 'pixi.js-legacy'
import { TweenMax } from 'gsap'
import emitter, { EVENT_TYPE } from '../../events'
import { getDevice } from '../../utils/deviceDetect'
import AssetsManager from '../data/AssetsManager'
import DataManager from '../data/DataManager'

/**
 * AwardPointerViewクラス
 */
class AwardPointerView extends PIXI.Container {
  // ---------------------------------------------------------------------------------------------------------
  // --	AwardPointerView
  // ---------------------------------------------------------------------------------------------------------

  /**
   * コンストラクタ
   */
  constructor(model) {
    super()

    // モデルを保持
    this.model = model

    // 位置を保持
    this.position =
      getDevice() === 'pc' ? this.model.position.pc : this.model.position.sp

    // 位置を初期化
    this.x = this.position.x
    this.y = this.position.y

    // SP版のクリック判定用変数
    this.touchStartPos = new PIXI.Point()
    this.touchStartTime = 0

    // ポインターコンテナ
    this.pointerContainer = new PIXI.Container()
    this.addChild(this.pointerContainer)

    // ラベル配置
    if (getDevice() === 'pc') {
      this.label = AssetsManager.getSpriteByName(`award_pin.png`)
    } else {
      this.label = AssetsManager.getSpriteByName(`award_pin_sp.png`)
    }
    this.label.anchor.set(0.0, 1.0)
    if (getDevice() === 'pc') {
      this.label.scale.set(0.5, 0.5)
      this.label.x = 2
      this.label.y = 0.5
    } else {
      this.label.scale.set(0.5, 0.5)
      this.label.x = 2
      this.label.y = 0.5
    }
    this.pointerContainer.addChild(this.label)

    // ポインター配置
    // this.pointer = AssetsManager.getSpriteByName('town_pointer.png')
    // this.pointer.anchor.set(0, 0)
    // if (getDevice() === 'pc') {
    //   this.pointer.scale.set(0.5, 0.5)
    // }
    // this.pointer.x = 20
    // this.pointer.y = -50
    // this.pointerContainer.addChild(this.pointer)

    // ヒットエリアコンテナ
    this.haContainer = new PIXI.Container()

    this.haContainer.buttonMode = true
    this.haContainer.interactive = true
    this.haContainer.alpha = 0.0
    this.addChild(this.haContainer)

    // ヒットエリアのレクタングル計算
    let minX = 9999
    let maxX = -9999
    let minY = 9999
    let maxY = -9999

    const labelBounds = this.label.getLocalBounds()
    minX = labelBounds.x / 2
    maxX = minX + labelBounds.width / 2
    minY = labelBounds.y / 2
    maxY = minY + labelBounds.height / 2

    // ヒットエリア生成
    const roomHa = new PIXI.Graphics()
    roomHa.beginFill(0x000000)
    roomHa.drawRect(minX, minY, maxX - minX, maxY - minY)
    roomHa.endFill()
    this.haContainer.addChild(roomHa)

    // 入力（PC）
    this.haContainer.on('mouseover', e => {
      this.onRollOver()
      this.emit(EVENT_TYPE.POINTER_ROLL_OVER)
    })
    this.haContainer.on('click', e => {
      window.open('/award-vol2/', '_blank')
    })

    this.haContainer.on('tap', () => {
      window.open('/award-vol2/', '_blank')
    })

    // リセット
    this.reset()
  }

  reset() {
    // 表示初期化
    TweenMax.killTweensOf(this.label)
    TweenMax.set(this.label, { alpha: 1 })
    TweenMax.killTweensOf(this.pointerContainer)
    TweenMax.set(this.pointerContainer, { alpha: 0, rotation: 0 })

    // 操作不可にする
    this.setEnabled(false)
  }

  setEnabled(bool) {
    this.haContainer.interactive = bool
  }

  // ---------------------------------------------------------------------------------------------------------
  // --	登場
  // ---------------------------------------------------------------------------------------------------------

  enter() {
    // ポインターの動き
    TweenMax.killTweensOf(this.pointerContainer)
    TweenMax.to(this.pointerContainer, 0.2, {
      y: -20,
      alpha: 1,
      rotation: -0.08,
      ease: 'power1.out',
      delay: this.model.enterDelay,
    })
    TweenMax.to(this.pointerContainer, 0.2, {
      y: 0,
      rotation: 0.0,
      ease: 'power1.in',
      delay: this.model.enterDelay + 0.3,
    })

    // 操作可能にする
    TweenMax.delayedCall(this.model.enterDelay + 0.6, () => {
      this.setEnabled(true)
    })
  }

  // ---------------------------------------------------------------------------------------------------------
  // --	ホバーアクション
  // ---------------------------------------------------------------------------------------------------------

  onRollOver() {
    // TweenMax.killTweensOf(this)
    // TweenMax.to(this.scale, 0.1, { x: 1.1, y: 1.1 })

    // ポインターの動き
    TweenMax.killTweensOf(this.pointerContainer)
    TweenMax.to(this.pointerContainer, 0.2, {
      y: -20,
      ease: 'power1.out',
    })
    TweenMax.to(this.pointerContainer, 0.2, {
      y: 0,
      ease: 'power1.in',
      delay: 0.3,
    })
  }

  // ---------------------------------------------------------------------------------------------------------
}

export default AwardPointerView
