import React, { useContext, useCallback } from 'react'
import { useTransition, animated } from 'react-spring'
import { useHistory } from 'react-router-dom'
import classNames from 'classnames'

import { Context } from '../Context'
import { ActionTypes } from '../constants'

import Alert from '../components/alert/Alert'
import Release from '../components/alert/Release'
import Login from '../components/avatar/Login'
import ModalHelp from '../components/avatar/ModalHelp'
import Point from '../components/avatar/Point'
import TownGuide from '../components/avatar/TownGuide'
import { snsDisconnet } from '../api'

const useGlobalModal = () => {
  const { state, dispatch } = useContext(Context)
  const transition = useTransition(state.modal, state.modal, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  })
  const history = useHistory()

  const modal = useCallback(
    item => {
      switch (item) {
        case 'Login':
          return (
            <Login
              onClose={() => {
                dispatch({ type: ActionTypes.setModal, payload: null })
              }}
            />
          )
        case 'LoginChestClick':
          return (
            <Login
              onClose={() => {
                dispatch({ type: ActionTypes.setModal, payload: null })
              }}
              isChestClick={true}
            />
          )
        case 'LoginMyroom':
          return (
            <Login
              onClose={() => {
                dispatch({ type: ActionTypes.isShowMyroomAlert, payload: true })
              }}
            />
          )
        case 'Point':
          return (
            <ModalHelp
              onClose={() => {
                dispatch({ type: ActionTypes.setModal, payload: null })
              }}
              isHelp="true"
              length={1}
              render={({ button }) => <Point>{button}</Point>}
            />
          )
        case 'Help':
          return (
            <ModalHelp
              onClose={() => {
                dispatch({ type: ActionTypes.setModal, payload: null })
              }}
              isHelp="true"
              length={1}
              render={({ button }) => <TownGuide>{button}</TownGuide>}
            />
          )
        case 'Disconnect':
          return (
            <Alert>
              <Release
                onCancel={() => {
                  dispatch({ type: ActionTypes.setModal, payload: null })
                }}
                onDisconnect={() => {
                  snsDisconnet()
                  dispatch({ type: ActionTypes.setModal, payload: null })
                }}
              />
            </Alert>
          )
        case 'Error':
        default:
          return null
      }
    },
    [dispatch]
  )

  return transition.map(({ item, key, props }) => {
    const modalComponent = modal(item)
    const modalTallType = ['Point']
    const modalAreaClass = classNames('ModalArea', {
      '-spOverflowYScroll': modalTallType.includes(item),
    })

    return modalComponent !== null ? (
      <animated.div key={key} style={props} className={modalAreaClass}>
        {modalComponent}
      </animated.div>
    ) : null
  })
}

export default useGlobalModal
