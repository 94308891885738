import * as PageName from './PageName'

const TREASURECHEST = new Map([
  [
    PageName.PARK,
    [
      // position : 座標 + 画像の半分
      {
        close: {
          name: 'park_1pt_close',
          position: { x: 177 + 70.5, y: 210 + 72 },
          action: 'open',
        },
        open: {
          name: 'park_1pt_open',
          position: { x: 177 + 70.5, y: 210 + 72 },
        },
      },
      {
        close: {
          name: 'park_5pt_close',
          position: { x: 177 + 70.5, y: 210 + 72 },
          action: 'open',
        },
        open: {
          name: 'park_5pt_open',
          position: { x: 177 + 70.5, y: 210 + 72 },
        },
      },
    ],
  ],
  [
    PageName.CAFE,
    [
      {
        close: {
          name: 'cafe_1pt_close',
          position: { x: 1041 + 66, y: 469 + 51.5 },
          action: 'open',
        },
        open: {
          name: 'cafe_1pt_open',
          position: { x: 1041 + 66, y: 469 + 51.5 },
        },
      },
      {
        close: {
          name: 'cafe_5pt_close',
          position: { x: 1043 + 66, y: 472 + 51.5 },
          action: 'open',
        },
        open: {
          name: 'cafe_5pt_open',
          position: { x: 1043 + 66, y: 472 + 51.5 },
        },
      },
    ],
  ],
  [
    PageName.SCHOOL,
    [
      {
        close: {
          name: 'school_1pt_close',
          position: { x: 955 + 71, y: 415 + 85.5 },
          action: 'open',
        },
        open: {
          name: 'school_1pt_open',
          position: { x: 955 + 71, y: 415 + 85.5 },
        },
      },
      {
        close: {
          name: 'school_5pt_close',
          position: { x: 955 + 71, y: 415 + 85.5 },
          action: 'open',
        },
        open: {
          name: 'school_5pt_open',
          position: { x: 955 + 71, y: 415 + 85.5 },
        },
      },
    ],
  ],
  // [
  //   PageName.BANK,
  // ],
  [
    PageName.HOUSE,
    [
      {
        close: {
          name: 'house_1pt_close',
          position: { x: 1061 + 63, y: 529 + 51 },
          action: 'open',
        },
        open: {
          name: 'house_1pt_open',
          position: { x: 1061 + 63, y: 529 + 51 },
        },
      },
      {
        close: {
          name: 'house_5pt_close',
          position: { x: 1061 + 63, y: 529 + 51 },
          action: 'open',
        },
        open: {
          name: 'house_5pt_open',
          position: { x: 1062 + 64, y: 530 + 50 },
        },
      },
    ],
  ],
])

export default TREASURECHEST
