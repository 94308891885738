import React from 'react'
import PropTypes from 'prop-types'

const Release = ({ onCancel, onDisconnect }) => {
  return (
    <div className="ModalAlert -release">
      <div className="__wrap">
        <h1>保存データを削除</h1>
        <p>
          データ削除を行うと、キャラクターデータが消去されます。
          <br />
          それでもよろしければ「削除へ進む」ボタンを押してください。
          <br />
          ※次のステップへ移動すると、データ消去に同意したものとみなします。
        </p>
        <div className="__btnSet">
          <div className="__btn">
            <button onClick={onCancel}>キャンセル</button>
          </div>
          <div className="__btn">
            <button onClick={onDisconnect}>解除へ進む</button>
          </div>
        </div>
      </div>
    </div>
  )
}

Release.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onDisconnect: PropTypes.func.isRequired,
}

export default Release
