import * as PIXI from 'pixi.js-legacy'
import { TweenMax } from 'gsap'
import emitter, { EVENT_TYPE } from '../../events'
import DataManager from '../data/DataManager'
import LoadingView from '../loading/LoadingView'
import LandingView from '../loading/LandingView'

/**
 * Mapクラス
 */
class Transition {
  // ---------------------------------------------------------------------------------------------------------
  // --	Transition
  // ---------------------------------------------------------------------------------------------------------

  /**
   * コンストラクター
   */
  constructor() {
    this.isWipeOut = true
  }

  init() {
    //App参照
    this.app = DataManager.getData('front')

    //Canvasかどうかをフラグで保持
    this.isCanvas = this.app.renderer instanceof PIXI.CanvasRenderer
    // console.log('this.isCanvas:' + this.isCanvas)

    //トランジション全体のコンテナ
    this.transitionContainer = new PIXI.Container()
    this.app.stage.addChild(this.transitionContainer)

    //マスクされるコンテナ
    this.maskedContainer = new PIXI.Container()
    this.transitionContainer.addChild(this.maskedContainer)

    //サークルコンテナ
    this.circleContainer = new PIXI.Container()
    this.maskedContainer.addChild(this.circleContainer)

    //背景の丸
    this.circle = new PIXI.Graphics()
    this.circle.beginFill(0xfffaec)
    this.circle.drawCircle(0, 0, 50)
    this.circle.endFill()
    this.circleContainer.addChild(this.circle)

    //ラインディング配置
    this.landingView = new LandingView()
    this.maskedContainer.addChild(this.landingView)

    //マスク用コンテナ
    this.holeMaskContainer = new PIXI.Container()
    this.transitionContainer.addChild(this.holeMaskContainer)

    //マスク用オブジェクト生成
    this.holeMask = new PIXI.Graphics()
    this.holeMask.beginFill(0xfffaec)
    this.holeMask.moveTo(-600, -600)
    this.holeMask.lineTo(-600, 600)
    this.holeMask.lineTo(600, 600)
    this.holeMask.lineTo(600, -600)
    this.holeMask.lineTo(-600, -600)
    this.holeMask.beginHole()
    this.holeMask.moveTo(0, 500)
    this.holeMask.bezierCurveTo(
      324 - 600,
      1100 - 600,
      100 - 600,
      876 - 600,
      100 - 600,
      600 - 600
    )
    this.holeMask.bezierCurveTo(
      100 - 600,
      324 - 600,
      324 - 600,
      100 - 600,
      600 - 600,
      100 - 600
    )
    this.holeMask.bezierCurveTo(
      876 - 600,
      100 - 600,
      1100 - 600,
      324 - 600,
      1100 - 600,
      600 - 600
    )
    this.holeMask.bezierCurveTo(
      1100 - 600,
      876 - 600,
      876 - 600,
      1100 - 600,
      600 - 600,
      1100 - 600
    )
    this.holeMask.endFill()
    this.holeMaskContainer.addChild(this.holeMask)
    TweenMax.set(this.holeMask, { width: 0, height: 0 })

    this.sqTop = new PIXI.Graphics()
    this.sqTop.beginFill(0xfffaec)
    this.sqTop.drawRect(0, 0, 1200, 600)
    this.sqTop.endFill()
    this.sqTop.x = -600
    this.sqTop.y = -600
    this.holeMaskContainer.addChild(this.sqTop)

    this.sqBottom = new PIXI.Graphics()
    this.sqBottom.beginFill(0xfffaec)
    this.sqBottom.drawRect(0, -600, 1200, 600)
    this.sqBottom.endFill()
    this.sqBottom.x = -600
    this.sqBottom.y = 600
    this.holeMaskContainer.addChild(this.sqBottom)

    this.sqLeft = new PIXI.Graphics()
    this.sqLeft.beginFill(0xfffaec)
    this.sqLeft.drawRect(0, 0, 600, 1200)
    this.sqLeft.endFill()
    this.sqLeft.x = -600
    this.sqLeft.y = -600
    this.holeMaskContainer.addChild(this.sqLeft)

    this.sqRight = new PIXI.Graphics()
    this.sqRight.beginFill(0xfffaec)
    this.sqRight.drawRect(-600, 0, 600, 1200)
    this.sqRight.endFill()
    this.sqRight.x = 600
    this.sqRight.y = -600
    this.holeMaskContainer.addChild(this.sqRight)

    //マスク適用
    if (!this.isCanvas) {
      this.maskedContainer.mask = this.holeMaskContainer
    } else {
      this.holeMaskContainer.visible = false
    }

    //センタリング
    window.addEventListener('resize', this.onResize.bind(this))
    this.onResize()

    //最初のアセッツが読み込まれたらLoadingViewを生成する（一度だけ）
    emitter.once(EVENT_TYPE.LOAD_COMPLETE, this.initLoadingView.bind(this))
  }

  // ---------------------------------------------------------------------------------------------------------
  // --	 ローディングの初期化
  // ---------------------------------------------------------------------------------------------------------
  initLoadingView() {
    this.loadingView = new LoadingView()
    this.maskedContainer.addChild(this.loadingView)
  }

  // ---------------------------------------------------------------------------------------------------------
  // --	ワイプアウト
  // ---------------------------------------------------------------------------------------------------------

  /**
   * ワイプアウト発動
   */
  wipeOut() {
    return new Promise((resolve, reject) => {
      // console.log('wipeOut', this.isWipeOut)
      if (!this.isWipeOut) {
        resolve()
        return
      }
      //フラグを立てる
      this.isWipeOut = false

      //Canvas用の処理
      if (this.isCanvas) {
        this.maskedContainer.visible = true
        this.holeMaskContainer.visible = false
      }

      // DOMを表示する
      document.querySelector('#front-container').style.display = 'block'

      // ローディング初期化
      if (this.loadingView) this.loadingView.reset()

      // マスクの表示を初期化
      TweenMax.killTweensOf(this.holeMask)
      TweenMax.set(this.holeMask, { width: 0, height: 0 })
      this.updateMask()

      // イベント発行
      emitter.emit(EVENT_TYPE.WIPE_OUT_START)

      // サークル（背景）のTween
      TweenMax.set(this.circle, { width: 0, height: 0 })
      TweenMax.to(this.circle, 0.6, {
        width: 100,
        height: 100,
        ease: 'power3.in',
        onComplete: () => {
          // console.log('wipeOut--complete')
          emitter.emit(EVENT_TYPE.WIPE_OUT_COMPLETE)
          this.isWipeOut = true
          resolve()
        },
      })

      // トランジションを表示する
      this.transitionContainer.visible = true
    })
  }

  // ---------------------------------------------------------------------------------------------------------
  // --	ワイプイン
  // ---------------------------------------------------------------------------------------------------------

  /**
   * ワイプイン発動
   */
  wipeIn() {
    return new Promise(resolve => {
      // console.log('wipeIn')

      //イベント発行
      emitter.emit(EVENT_TYPE.WIPE_IN_START)

      //Canvas用の処理
      if (this.isCanvas) {
        this.maskedContainer.visible = false
        this.holeMaskContainer.visible = true
      }

      //Tween
      TweenMax.killTweensOf(this.holeMask)
      TweenMax.set(this.holeMask, { width: 0, height: 0 })
      TweenMax.to(this.holeMask, 0.6, {
        width: 1200,
        height: 1200,
        ease: 'power3.in',
        onUpdate: this.updateMask.bind(this),
        onComplete: () => {
          // console.log('wipeIn--complete')
          //トランジションを非表示にする
          this.transitionContainer.visible = false

          //ローディング初期化
          if (this.loadingView) this.loadingView.reset()

          //ランディングページがある時は破棄
          if (this.landingView) {
            this.landingView.destroy()
            this.landingView = null
          }

          //DOMを非表示にする
          document.querySelector('#front-container').style.display = 'none'

          emitter.emit(EVENT_TYPE.WIPE_IN_COMPLETE)
          resolve()
        },
      })
    })
  }

  /**
   * マスクの表示をアップデート
   */
  updateMask() {
    //スケール計算
    const scale = this.holeMask.width / 1200

    //穴の大きさを計算
    const holeWidth = 1000 * scale

    //マスクのサイズ変更
    this.sqTop.height = 600 - holeWidth / 2
    this.sqBottom.height = 600 - holeWidth / 2
    this.sqLeft.width = 600 - holeWidth / 2
    this.sqRight.width = 600 - holeWidth / 2
  }

  // ---------------------------------------------------------------------------------------------------------
  // --	リサイズ関連
  // ---------------------------------------------------------------------------------------------------------

  /**
   * リサイズ
   */
  onResize() {
    //スクリーンサイズ
    const screenWidth = this.app.renderer.width / this.app.renderer.resolution
    const screenHeight = this.app.renderer.height / this.app.renderer.resolution

    //センター合わせ
    this.transitionContainer.x = screenWidth / 2
    this.transitionContainer.y = screenHeight / 2

    //サークルコンテナのサイズ
    const radius = Math.hypot(screenWidth, screenHeight)
    this.circleContainer.scale.set(radius / 100, radius / 100)

    //ホールマスクのサイズ
    this.holeMaskContainer.scale.set(radius / 1000, radius / 1000)
  }

  // ---------------------------------------------------------------------------------------------------------
}

const instance = new Transition()

export default instance
