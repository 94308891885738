import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import Help from './Help'
import ButtonClose from '../modal/ButtonClose'

import BtnNext from '../../img/modal/Btn_next.png'
import BtnNextSp from '../../img/modal/Btn_next__sp.png'
import BtnMake from '../../img/modal/Btn_make.png'
import BtnMakeSp from '../../img/modal/Btn_make__sp.png'
import ButtonCloseX from '../modal/ButtonCloseX'

const ModalHelp = ({ length, isHelp, onClose, render }) => {
  const [pageIdx, setPageIdx] = useState(0)

  const button = useMemo(() => {
    if (isHelp) {
      if (pageIdx < length - 1) {
        return (
          <>
            <div className="ModalButton -next">
              <button onClick={_ => setPageIdx(pageIdx + 1)}>
                <img src={BtnNext} alt="進む" className="-pc" />
                <img src={BtnNextSp} alt="進む" className="-sp" />
              </button>
            </div>
            <ButtonCloseX onClose={onClose} />
          </>
        )
      }

      return (
        <>
          <ButtonCloseX onClose={onClose} />
          <ButtonClose onClose={onClose} />
        </>
      )
    }

    if (pageIdx < length - 1) {
      return (
        <div className="ModalButton -next">
          <button onClick={_ => setPageIdx(pageIdx + 1)}>
            <img src={BtnNext} alt="進む" className="-pc" />
            <img src={BtnNextSp} alt="進む" className="-sp" />
          </button>
        </div>
      )
    }

    return (
      <div className="ModalButton -make">
        <button onClick={_ => onClose()}>
          <img src={BtnMake} alt="アバターを作る" className="-pc" />
          <img src={BtnMakeSp} alt="アバターを作る" className="-sp" />
        </button>
      </div>
    )
  }, [length, pageIdx, setPageIdx])

  return (
    <Help
      current={pageIdx}
      length={length}
      onNext={() => setPageIdx(pageIdx + 1)}
      onPrev={() => setPageIdx(pageIdx - 1)}
    >
      {render({ button, index: pageIdx })}
    </Help>
  )
}

ModalHelp.defaultProps = {
  isHelp: false,
}

ModalHelp.propTypes = {
  length: PropTypes.number.isRequired,
  isHelp: PropTypes.bool,
  render: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default ModalHelp
