import React, { useContext } from 'react'
import { Context } from '../../Context'
import { useHistory } from 'react-router-dom'
import { ActionTypes } from '../../constants'

import iconQuestion from '../../img/myroom/icon_question.png'

const MyroomStatus = ({ point }) => {
  const { state, dispatch } = useContext(Context)
  const history = useHistory()
  const { avatarPath } = state.userData

  const onClickPoint = () => {
    dispatch({
      type: ActionTypes.isShowMyroomGuide,
      payload: true,
    })
  }

  return (
    <div className="MyroomStatus">
      <div className="MyroomStatus_inner">
        <div className="MyroomStatus_avatar">
          <div className="avatar">
            <img src={avatarPath.open} alt="" />
          </div>
        </div>
        <div className="MyroomStatus_info">
          <div className="MyroomStatus_info_name">
            <h3>{state.nickname}</h3>
            <p>
              <span>の</span>
              おへや
            </p>
          </div>
          <div className="MyroomStatus_info_point">
            <div className="point">{point}pt</div>
            <div className="question" onClick={onClickPoint}>
              <img src={iconQuestion} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MyroomStatus
