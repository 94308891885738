import React, { useReducer } from 'react'
import PropTypes from 'prop-types'
import Cookies from 'js-cookie'
import { useLocation } from 'react-router-dom'
import { ActionTypes } from './constants'

/**
 * 初期値
 */
const initialState = {
  loading: true,
  userData: {
    userId: null,
    userName: '',
    userPoint: 0,
    userRank: 0, // 0:bronze, 1:silver, 2:gold,
    gotPoint: false,
    newItem: false,
    rankUp: false,
    avatarPath: {
      open: '',
      close: '',
    },
    userStatus: {
      avatarText: '',
      avatarLink: null,
      avatarButton: null,
      statusImage: '',
    },
  },
  modalData: null,
  newsData: [],
  modal: null,
  showedIntro: localStorage.getItem('showedIntro') || false,
  showedCampaign: false,
  showedMyroomCampaign: localStorage.getItem('showedMyroomCampaign') || false,
  showedAw2024Campaign: localStorage.getItem('showedAw2024Campaign') || false,
  showedAw2024CampaignMyroom:
    localStorage.getItem('showedAw2024CampaignMyroom') || false,
  showedPartsAddInfo: localStorage.getItem('showedPartsAddInfo') || false,
  showedPartsAddInfoMyroom:
    localStorage.getItem('showedPartsAddInfoMyroom') || false,
  showedMyroomStart: localStorage.getItem('showedMyroomStart') || false,
  showedMyroomCookpadCampaign:
    localStorage.getItem('showedMyroomCookpadCampaign') || false,
  showedMyroomAutumnCampaign:
    localStorage.getItem('showedMyroomAutumnCampaign') || false,
  showedPointNotification: false,
  showedRankUp: Cookies.get('showedRankUp'),
  showedNewItemTop: Cookies.get('showedNewItemTop'),
  showedNewItemAvatar: Cookies.get('showedNewItemAvatar'),
  chestData: {
    result: false,
    open: false,
    point: 0,
    place: '',
  },
  awardUserPath: '',
  uid: null, // ここからMyRoom情報↓↓↓
  rid: null,
  nickname: '',
  roomParts: {
    wallpaper: {
      catId: '',
      partsId: '',
    },
    floor: {
      catId: '',
      partsId: '',
    },
  },
  isShowMyroomAlert: false,
  isShowMyroomGuide: false,
}

/**
 * Reducer
 */
const reducer = (state, action) => {
  switch (action.type) {
    case ActionTypes.setLoading: {
      return { ...state, loading: action.payload }
    }
    case ActionTypes.pageMove: {
      return {
        ...state,
        pageMove: {
          loading: false,
          pathname: action.payload,
        },
      }
    }
    case ActionTypes.pageLoadCompleted: {
      return {
        ...state,
        pageMove: {
          ...state.pageMove,
          loading: true,
        },
      }
    }
    case ActionTypes.setUserData: {
      return { ...state, ...action.payload }
    }
    case ActionTypes.setRoomData: {
      return { ...state, ...action.payload }
    }
    case ActionTypes.setModal: {
      return { ...state, modal: action.payload }
    }
    case ActionTypes.showedIntro: {
      localStorage.setItem('showedIntro', true)
      return { ...state, showedIntro: true }
    }
    case ActionTypes.showedCampaign: {
      return { ...state, showedCampaign: true }
    }
    case ActionTypes.showedMyroomCampaign: {
      localStorage.setItem('showedMyroomCampaign', true)
      return { ...state, showedMyroomCampaign: true }
    }
    case ActionTypes.showedAw2024Campaign: {
      localStorage.setItem('showedAw2024Campaign', true)
      return { ...state, showedAw2024Campaign: true }
    }
    case ActionTypes.showedAw2024CampaignMyroom: {
      localStorage.setItem('showedAw2024CampaignMyroom', true)
      return { ...state, showedAw2024CampaignMyroom: true }
    }
    case ActionTypes.showedPartsAddInfo: {
      localStorage.setItem('showedPartsAddInfo', true)
      return { ...state, showedPartsAddInfo: true }
    }
    case ActionTypes.showedPartsAddInfoMyroom: {
      localStorage.setItem('showedPartsAddInfoMyroom', true)
      return { ...state, showedPartsAddInfoMyroom: true }
    }
    case ActionTypes.showedPointNotification: {
      return { ...state, showedPointNotification: true }
    }
    case ActionTypes.setChestStatus: {
      return { ...state, chestData: action.payload }
    }
    case ActionTypes.setAwardUserPath: {
      return { ...state, awardUserPath: action.payload }
    }
    case ActionTypes.showedMyroomStart: {
      localStorage.setItem('showedMyroomStart', true)
      return { ...state, showedMyroomStart: true }
    }
    case ActionTypes.showedMyroomCookpadCampaign: {
      localStorage.setItem('showedMyroomCookpadCampaign', true)
      return { ...state, showedMyroomCookpadCampaign: true }
    }
    case ActionTypes.showedMyroomAutumnCampaign: {
      localStorage.setItem('showedMyroomAutumnCampaign', true)
      return { ...state, showedMyroomAutumnCampaign: true }
    }
    case ActionTypes.setRoomParts: {
      return { ...state, room_parts: action.payload }
    }
    case ActionTypes.isShowMyroomAlert: {
      return { ...state, isShowMyroomAlert: action.payload }
    }
    case ActionTypes.isShowMyroomGuide: {
      return { ...state, isShowMyroomGuide: action.payload }
    }
    default:
      return { ...state }
  }
}

const Context = React.createContext(initialState)

function ContextProvider({ children }) {
  const loc = useLocation()

  // path だけ書き換える
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    pageMove: {
      loading: false,
      pathname: loc.pathname,
    },
  })
  return (
    <Context.Provider value={{ state, dispatch }}>{children}</Context.Provider>
  )
}

export { Context, ContextProvider }

ContextProvider.propTypes = {
  children: PropTypes.element.isRequired,
}
