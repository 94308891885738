import axios from 'axios'
import isObject from 'lodash.isobject'
import camelCase from 'lodash.camelcase'
import snakeCase from 'lodash.snakecase'
import Cookies from 'js-cookie'

const BASE_URL = process.env.REACT_APP_API_BASE_URL

const deepMapKeys = (obj, cb) => {
  if (!isObject(obj) || obj instanceof File) {
    return obj
  }

  return Array.isArray(obj)
    ? obj.map(val => deepMapKeys(val, cb))
    : Object.fromEntries(
        Object.entries(obj).map(([key, value]) => [
          cb(value, key),
          deepMapKeys(value, cb),
        ])
      )
}

const deepMapValues = (obj, cb) => {
  if (!isObject(obj) || obj instanceof File) {
    return cb(obj)
  }

  return Array.isArray(obj)
    ? obj.map(val => deepMapValues(val, cb))
    : Object.fromEntries(
        Object.entries(obj).map(([key, value]) => [
          key,
          deepMapValues(value, cb),
        ])
      )
}

const isJson = headers =>
  headers['content-type'] !== undefined &&
  headers['content-type'].indexOf('application/json') === 0

const toCamelCase = (data, headers) =>
  isJson(headers) ? deepMapKeys(data, (_, key) => camelCase(key)) : data

const toSnakeCase = (data, _) =>
  data instanceof FormData
    ? data
    : deepMapKeys(data, (_, key) => snakeCase(key))

const addResourcePath = (data, headers) =>
  isJson(headers)
    ? deepMapValues(data, value =>
        (value instanceof String || typeof value === 'string') &&
        value.indexOf('/resources/') === 0
          ? `${BASE_URL}${value}`
          : value
      )
    : data

const instance = axios.create({
  transformResponse: [
    ...axios.defaults.transformResponse,
    toCamelCase,
    addResourcePath,
  ],
  transformRequest: [toSnakeCase, ...axios.defaults.transformRequest],
  baseURL: BASE_URL,
  // withCredentials: true,
})

const getUid = () => {
  return Cookies.get('meiyasu_mytown[uid]')
}

const getRid = () => {
  return Cookies.get('meiyasu_mytown[rid]')
}

export const logout = () => {
  document.location.href = `${BASE_URL}/api/logout`
}

export const snsDisconnet = () => {
  document.location.href = `${BASE_URL}/api/delete`
}

export const fetchUser = async () => {
  try {
    const { data } = await instance.post('/api/toppage', {
      uid: getUid(),
    })
    return data
  } catch (error) {
    console.error(error)
  }
}

// マイルーム情報取得
export const fetchMyRoom = async () => {
  try {
    const { data } = await instance.post('/api/myroom/user/find', {
      uid: getUid(),
      rid: getRid(),
    })
    return data
  } catch (error) {
    console.error(error)
  }
}

// パーツ情報取得
export const fetchMyRoomParts = async () => {
  try {
    const { data } = await instance.post('/api/myroom/parts/list')
    return data
  } catch (error) {
    console.error(error)
  }
}

// マイルーム壁紙/床 登録
export const registMyRoomParts = async items => {
  try {
    const response = await instance.post('/api/myroom/parts/regist', {
      uid: getUid(),
      rid: getRid(),
      ...items,
    })
    return response.data
  } catch (error) {
    console.error(error)
  }
}

// プロフィール 登録
export const registProfile = async items => {
  try {
    const response = await instance.post('/api/myroom/profile/regist', {
      uid: getUid(),
      ...items,
    })
    // window.alert('rid : ' + response.data.rid)
    return response.data
  } catch (error) {
    console.error(error)
  }
}

// アンケート 登録
export const registEnquete = async items => {
  try {
    const response = await instance.post('/api/myroom/enquete/regist', {
      uid: getUid(),
      rid: getRid(),
      ...items,
    })
    return response.data
  } catch (error) {
    console.error(error)
  }
}

// アンケート 回答状況確認
export const fetchEnquete = async () => {
  try {
    const { data } = await instance.post('/api/myroom/enquete/check', {
      uid: getUid(),
      rid: getRid(),
    })
    return data
  } catch (error) {
    console.error(error)
  }
}

export const fetchRecipe = async date => {
  try {
    const { data } = await instance.post(`/api/cookpad/recipe?debug=${date}`, {
      uid: getUid(),
      rid: getRid(),
    })
    return data
  } catch (error) {
    console.error(error)
  }
}

export const fetchAvatar = async () => {
  try {
    const { data } = await instance.post('/api/avatar/get', { uid: getUid() })
    return data
  } catch (error) {
    console.error(error)
  }
}

// CHANGED secret word item
export const unlockSecret = async (secret, campId) => {
  try {
    const { data } = await instance.post('/api/avatar/secret', {
      uid: getUid(),
      secret,
      campId,
    })
    return data
  } catch (error) {
    console.error(error)
  }
}

export const confirmAvatar = async (ids, special) => {
  try {
    const response = await instance.post('/api/avatar/confirm', {
      uid: getUid(),
      partsId: {
        ...addRequireValues(ids, [
          'hairf',
          'hairb',
          'ebrow',
          'eyes',
          'nomo',
          'tops',
          'bottoms',
          'shoes',
        ]),
        special,
      },
    })

    // cookie に保存

    return response.data
  } catch (error) {
    console.error(error)
  }
}

const addRequireValues = (ids, requireIds) => {
  const keys = Object.keys(ids)
  return {
    ...ids,
    ...requireIds
      .filter(x => !keys.includes(x))
      .map(x => ({ [x]: null }))
      .reduce((a, c) => ({ ...c, ...a }), {}),
  }
}

export const saveAvatar = async (userId, prefix) => {
  try {
    const { data } = await instance.post('/api/avatar/save', {
      uid: userId,
      prefix,
    })
    return data
  } catch (error) {
    console.error(error)
  }
}

export const loadRoomsContent = async () => {
  try {
    const { data } = await instance.get('/resources/town/contents_new.json')
    return data
  } catch (error) {
    console.error(error)
  }
}

// 仮で静的ファイルを読んでる
// export const loadRoomsContent = async () => {
//   const data = await (await fetch('/sample_data/contents_new.json')).json()
//   return data
// }

// テレビバナー
export const loadMyroomTvBanner = async () => {
  const data = await (await fetch('/data/MyroomTvBanner.json')).json()
  return data
}

// 宝箱チェック
export const fetchTreasureChestState = async () => {
  try {
    const { data } = await instance.post('/api/town/treasure-check', {
      uid: getUid(),
    })
    return data
  } catch (error) {
    console.error(error)
  }
}

// 宝箱Open
export const openTreasureChestState = async (point, place) => {
  try {
    const { data } = await instance.post('/api/town/treasure', {
      uid: getUid(),
      point: point,
      place: place,
    })
    return data
  } catch (error) {
    console.error(error)
  }
}
