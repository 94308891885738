import React from 'react'
import PropTypes from 'prop-types'

const Shade = ({ onClick }) => {
  return <div onClick={onClick} className="Shade" />
}

Shade.defaultProps = {
  onClick: () => {},
}

Shade.propTypes = {
  onClick: PropTypes.func,
}

export default Shade
