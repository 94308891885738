import { useSpring } from 'react-spring'

const useModalIntro = () => {
  return useSpring({
    from: { transform: 'perspective(100px) translate3d(0px, 0px, -50px)' },
    to: { transform: 'perspective(100px) translate3d(0px, 0px, 0px)' },
    config: {
      friction: 17,
    },
  })
}

export default useModalIntro
