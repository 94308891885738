import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Link, useHistory } from 'react-router-dom'

import { Context } from '../../Context'

import TopicsBtn from '../../img/ui/Topics_btn.png'
import TopicsBtnSP from '../../img/ui/Btn_topics_sp.png'

const Topics = ({ style, pathname }) => {
  const { state } = useContext(Context)
  const history = useHistory()

  const { newsData } = state
  const { date, title } = newsData[0] || { date: '', title: '' }

  const paths = ['/', '/news']

  return (
    <div className="Topics" style={style}>
      {paths.includes(pathname) && (
        <>
          <button
            onClick={_ => history.push('/news')}
            className="Topics_button"
          >
            <img src={TopicsBtnSP} alt="Topics" />
          </button>

          <div className="Topics_wrap">
            <ul className="__List">
              <li>
                <Link to="/news">
                  <span>{date}</span>
                  <b>
                    {title.length > 20 ? `${title.substr(0, 20)}...` : title}
                  </b>
                </Link>
              </li>
            </ul>

            <Link to="/news" className="__Btn">
              <img src={TopicsBtn} alt="" />
            </Link>
          </div>
        </>
      )}
    </div>
  )
}

Topics.propTypes = {
  // style: PropTypes.object.isRequired,
  pathname: PropTypes.string.isRequired,
}

export default Topics
