import React from 'react'
import PropTypes from 'prop-types'
import useMount from '../../hooks/useMount'
import cssImport from '../../utils/cssImport'

import ModalClose01 from '../../img/modal/Btn_close.png'
import ModalClose01_sp from '../../img/modal/Btn_close__sp.png'

const ButtonClose = ({ onClose }) => {
  useMount(() => {
    cssImport('components/modal/ModalButton.scss')
  })

  return (
    <div className="ModalButton -close">
      <button onClick={_ => onClose()}>
        <img src={ModalClose01} alt="閉じる" className="-pc" />
        <img src={ModalClose01_sp} alt="閉じる" className="-sp" />
      </button>
    </div>
  )
}

ButtonClose.propTypes = {
  onClose: PropTypes.func,
}

export default ButtonClose
