import * as PIXI from 'pixi.js-legacy'
import { TweenMax } from 'gsap'
import { getDevice } from '../../utils/deviceDetect'
import emitter, { EVENT_TYPE } from '../../events'
import AssetsManager from '../data/AssetsManager'

/**
 * LandingViewクラス
 */
class LandingView extends PIXI.Container {
  // ---------------------------------------------------------------------------------------------------------
  // --	LandingView
  // ---------------------------------------------------------------------------------------------------------

  /**
   * [constructor description]
   */
  constructor() {
    super()

    // コンテナ配置
    const container = new PIXI.Container()
    this.addChild(container)

    //ロゴ用コンテナ
    this.logoContainer = new PIXI.Container()
    if (getDevice() === 'pc') {
      this.logoContainer.scale.set(0.5, 0.5)
      this.logoContainer.x = 1.5
      this.logoContainer.y = -94.5
    } else {
      this.logoContainer.scale.set(0.5 * 0.75, 0.5 * 0.75)
      this.logoContainer.x = 0
      this.logoContainer.y = -104
    }
    this.logoContainer.alpha = 0
    container.addChild(this.logoContainer)

    // ロゴのベース配置
    const logoBase = AssetsManager.getSpriteByName('landing_logo_base.png')
    logoBase.anchor.set(0.5, 0.5)
    this.logoContainer.addChild(logoBase)

    // ロゴのテキスト用コンテナ配置
    this.logoCharsContainer = new PIXI.Container()
    this.logoCharsContainer.x = -logoBase.width / 2
    this.logoCharsContainer.y = -logoBase.height / 2
    this.logoContainer.addChild(this.logoCharsContainer)

    // ロゴのテキスト配置
    for (let i = 1; i <= 5; i++) {
      const char = AssetsManager.getSpriteByName(`landing_logo_char${i}.png`)
      char.anchor.set(0.5, 0.5)
      char.scale.set(0.4, 0.4)
      char.alpha = 0
      this.logoCharsContainer.addChild(char)
      switch (i) {
        case 1:
          char.x = 106
          char.y = 106
          break
        case 2:
          char.x = 204
          char.y = 133
          break
        case 3:
          char.x = 317
          char.y = 113
          break
        case 4:
          char.x = 436
          char.y = 112
          break
        case 5:
          char.x = 565
          char.y = 124
          break
        default:
      }
    }

    // ロゴの飾り配置
    this.logoKazari = AssetsManager.getSpriteByName('landing_logo_kazari.png')
    this.logoKazari.anchor.set(0.5, 0.5)
    this.logoKazari.scale.set(0.4, 0.4)
    this.logoKazari.alpha = 0
    this.logoKazari.x = this.logoCharsContainer.x + 201
    this.logoKazari.y = this.logoCharsContainer.y + 69
    this.logoContainer.addChild(this.logoKazari)

    // キャラクター用コンテナ配置
    this.charaContainer = new PIXI.Container()
    if (getDevice() === 'pc') {
      this.charaContainer.scale.set(0.5, 0.5)
      this.charaContainer.x = -2
      this.charaContainer.y = 89.5
    } else {
      this.charaContainer.scale.set(0.5 * 0.75, 0.5 * 0.75)
      this.charaContainer.x = 0
      this.charaContainer.y = 35
    }
    container.addChild(this.charaContainer)

    // アバターベース配置
    this.avatarBase = AssetsManager.getSpriteByName('landing_chara_base.png')
    this.avatarBase.anchor.set(0.5, 0.5)
    this.avatarBase.scale.set(0.4, 0.4)
    this.avatarBase.alpha = 0.0
    this.charaContainer.addChild(this.avatarBase)

    // アバター配置
    this.avatarIndex = 0
    this.avatarContainer = new PIXI.Container()
    this.avatarContainer.alpha = 0
    this.charaContainer.addChild(this.avatarContainer)
    for (let i = 1; i <= 3; i++) {
      const avatar = AssetsManager.getSpriteByName(
        `landing_chara_avatar${i}.png`
      )
      avatar.anchor.set(0.5, 0.0)
      avatar.y = -130
      avatar.alpha = i === 1 ? 1 : 0
      this.avatarContainer.addChild(avatar)
    }

    // アバターマスク
    this.avatarMaskContainer = new PIXI.Container()
    this.avatarMaskContainer.scale.set(0.4, 0.4)
    this.charaContainer.addChild(this.avatarMaskContainer)

    // マスク用のシェイプを配置
    const circleMask = new PIXI.Graphics()
    circleMask.beginFill(0x990000)
    circleMask.drawCircle(0, 0, 120)
    circleMask.endFill()
    this.avatarMaskContainer.addChild(circleMask)
    const sqMask = new PIXI.Graphics()
    sqMask.beginFill(0x990000)
    sqMask.drawRect(-120, -140, 240, 140)
    sqMask.endFill()
    this.avatarMaskContainer.addChild(sqMask)

    // マスクをかける
    this.avatarContainer.mask = this.avatarMaskContainer

    // 登場
    this.enter()
  }

  enter() {
    //ロゴ登場
    TweenMax.to(this.logoContainer, 0.5, {
      alpha: 1,
      ease: 'none',
      delay: 0.5,
      onComplete: () => {
        for (let i = 0; i < 5; i++) {
          const char = this.logoCharsContainer.getChildAt(i)
          TweenMax.to(char, 0.5, {
            alpha: 1,
            ease: 'none',
            delay: 0.1 + 0.2 * i,
          })
          TweenMax.to(char.scale, 0.5, {
            x: 1,
            y: 1,
            ease: 'back.out(2.4)',
            delay: 0.1 + 0.2 * i,
          })
          if (i === 2) {
            TweenMax.to(this.logoKazari, 0.5, {
              alpha: 1,
              ease: 'none',
              delay: 0.1 + 0.2 * i,
            })
            TweenMax.to(this.logoKazari.scale, 0.5, {
              x: 1,
              y: 1,
              ease: 'back.out(2.4)',
              delay: 0.1 + 0.2 * i,
            })
          }
        }
      },
    })

    //キャラ登場
    TweenMax.to(this.avatarBase, 0.6, {
      alpha: 1,
      ease: 'none',
      delay: 2.5,
    })
    TweenMax.to(this.avatarBase.scale, 0.6, {
      x: 1,
      y: 1,
      ease: 'back.out(1.8)',
      delay: 2.5,
    })
    TweenMax.to(this.avatarMaskContainer.scale, 0.6, {
      x: 1,
      y: 1,
      ease: 'back.out(1.8)',
      delay: 2.5,
    })
    TweenMax.to(this.avatarContainer, 0.2, {
      alpha: 1,
      ease: 'none',
      delay: 2.9,
      onComplete: () => {
        emitter.emit(EVENT_TYPE.LANDING_ENTERED)
        this.playLoop()
      },
    })
  }

  playLoop() {
    TweenMax.killTweensOf(this)
    TweenMax.to(this, 3.0, {
      onComplete: () => {
        // インデックス変更
        this.avatarIndex++
        if (this.avatarIndex >= 3) this.avatarIndex = 0

        // 表示変更
        for (let i = 0; i < 3; i++) {
          const avatar = this.avatarContainer.getChildAt(i)
          const opAlpha = i === this.avatarIndex ? 1 : 0
          TweenMax.killTweensOf(avatar)
          TweenMax.to(avatar, 0.5, { alpha: opAlpha, ease: 'none' })
        }
        // ループ
        this.playLoop()
      },
    })
  }

  destroy() {
    TweenMax.killTweensOf(this)
    this.parent.removeChild(this)
  }

  // ---------------------------------------------------------------------------------------------------------
}

export default LandingView
