import React from 'react'
import { getDevice } from '../../utils/deviceDetect'

import Btn_help from '../../img/ui/Btn_Help.png'

const Help = ({ onClick, pathname }) => {
  const device = getDevice()
  const pathsPC = ['/', '/news', '/school', '/cafe', '/house', '/park']
  const pathsSP = ['/', '/news']
  return (
    <div className="Help">
      {device == 'pc'
        ? pathsPC.includes(pathname) && (
            <button onClick={_ => onClick()} className="Help_btn">
              <img src={Btn_help} alt="" />
            </button>
          )
        : pathsSP.includes(pathname) && (
            <button onClick={_ => onClick()} className="Help_btn">
              <img src={Btn_help} alt="" />
            </button>
          )}
    </div>
  )
}

export default Help
