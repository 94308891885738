import React from 'react'
import PropTypes from 'prop-types'
import Shade from '../modal/Shade'
import Arrows from '../modal/Arrows'

const Help = ({ current, length, children, onNext, onPrev }) => {
  return (
    <>
      <Shade />
      <Arrows
        current={current}
        length={length}
        onNext={onNext}
        onPrev={onPrev}
      />

      {children}
    </>
  )
}

Help.propTypes = {
  current: PropTypes.number.isRequired,
  length: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
  onNext: PropTypes.func.isRequired,
  onPrev: PropTypes.func.isRequired,
}

export default Help
