import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { animated, useSpring } from 'react-spring'

import { Context } from '../../Context'
import { ActionTypes } from '../../constants'

import { Link, useHistory } from 'react-router-dom'
import Shade from '../modal/Shade'
import ButtonCloseX from '../modal/ButtonCloseX'
import ShareButtons from './ShareButtons'

import Menu_myroom from '../../img/ui/Menu_text__myroom.png'
import Menu_towntop from '../../img/ui/Menu_text__towntop.png'
import Menu_park from '../../img/ui/Menu_text__park.png'
import Menu_bank from '../../img/ui/Menu_text__bank.png'
import Menu_cafe from '../../img/ui/Menu_text__cafe.png'
import Menu_school from '../../img/ui/Menu_text__school.png'
import Menu_apartment from '../../img/ui/Menu_text__apartment.png'
import Menu_topictnews from '../../img/ui/Menu_text__topictnews.png'
import Btn_Avatar from '../../img/ui/Btn_avatar.png'
import Menu_board from '../../img/ui/Menu_board__title.png'
import Menu_Login from '../../img/ui/Menu_btn__Login.png'
import Menu_Logout from '../../img/ui/Menu_btn__Logout.png'
import Menu_Signup from '../../img/ui/Menu_btn__Signup.png'
import Btn_Disconnect from '../../img/ui/Menu_btn__disconnect.png'
import Myroom_menu_icon01 from '../../img/ui/Myroom_menu_icon01.png'
import Myroom_menu_icon02 from '../../img/ui/Myroom_menu_icon02.png'
import Myroom_menu_icon03 from '../../img/ui/Myroom_menu_icon03.png'
import Myroom_menu_icon04 from '../../img/ui/Myroom_menu_icon04.png'
import Myroom_menu_icon05 from '../../img/ui/Myroom_menu_icon05.png'
import Myroom_menu_icon06 from '../../img/ui/Myroom_menu_icon06.png'
import Myroom_menu_icon07 from '../../img/ui/Myroom_menu_icon07.png'
import useModalIntro from '../../hooks/useModalIntro'
import { getDevice } from '../../utils/deviceDetect'

const MenuBox = ({
  onClose,
  onLogin,
  onLogout,
  onDisconnect,
  isLogin,
  style,
  pathname,
}) => {
  const history = useHistory()
  const props = useModalIntro()
  const { state, dispatch } = useContext(Context)
  const spProps = useSpring({
    from: {
      opacity: 0,
      transform: 'translateX(-50px)',
    },
    to: {
      opacity: 1,
      transform: 'translateX(0px)',
    },
  })
  const isMyRoom = pathname === '/myroom/main'

  let shareURL
  let shareTxt
  if (isMyRoom) {
    shareURL = encodeURIComponent('https://meijiyasuda-ladies-mytown.jp/myroom')
    shareTxt = encodeURIComponent(
      'マイルームの中には日常に役立つ楽しいコンテンツが盛りだくさん。お気に入りをつめこんで、あなた好みのお部屋をつくろう！'
    )
  } else {
    shareURL = encodeURIComponent(
      window.location.origin || 'https://meijiyasuda-ladies-mytown.jp/'
    )
    shareTxt = encodeURIComponent(
      '毎日をアクティブに、思いっきり輝く。そんなオトナ女子たちが暮らすのが、「マイタウン」。街中には、これからのライフスタイルをもっと豊かにしてくれるヒントがいっぱいです。さあ、あなたも今すぐ探検してみよう！'
    )
  }
  const twitterShareURL = `https://twitter.com/intent/tweet?url=${shareURL}&text=${shareTxt}`
  const facebookShareURL = `https://www.facebook.com/sharer/sharer.php?u=${shareURL}`
  const lineShareURL = `https://timeline.line.me/social-plugin/share?url=${shareURL}`

  return (
    <animated.div
      style={style}
      className="MenuBoxArea"
      aria-hidden="false"
      id="menubox"
    >
      <Shade onClick={() => onClose()} />
      <animated.div
        style={getDevice() === 'pc' ? props : spProps}
        className="MenuBox"
      >
        <div className="MenuBox_wrap">
          {isMyRoom ? (
            <nav className="__NavArea myroom_nav">
              <div className="myroom_nav_iconArea">
                <div className="myroom_nav_iconArea_top">
                  <ul className="myroom_nav_iconList">
                    {/* <li className="iconListItem">
                      <div className="icon">
                        <img src={Myroom_menu_icon01} alt="占い" />
                      </div>
                      <p className="caption">占い</p>
                    </li>
                    <li className="iconListItem">
                      <div className="icon">
                        <img src={Myroom_menu_icon02} alt="マイストーリー" />
                      </div>
                      <p className="caption">
                        マイ
                        <br />
                        ストーリー
                      </p>
                    </li>
                    <li className="iconListItem">
                      <div className="icon">
                        <img src={Myroom_menu_icon03} alt="アンケート" />
                      </div>
                      <p className="caption">アンケート</p>
                    </li> */}
                    {/* <li className="iconListItem">
                      <div className="icon">
                        <img src={Myroom_menu_icon04} alt="企業タイアップ" />
                      </div>
                      <p className="caption">
                        企業
                        <br />
                        タイアップ
                      </p>
                    </li> */}
                    <a
                      href="https://www.instagram.com/meijiyasuda_women.official/"
                      target="_blank"
                    >
                      <li className="iconListItem">
                        <div className="icon">
                          <img src={Myroom_menu_icon05} alt="公式Instagram" />
                        </div>
                        <p className="caption">
                          公式
                          <br />
                          Instagram
                        </p>
                      </li>
                    </a>
                  </ul>
                </div>
                <div className="myroom_nav_iconArea_bottom">
                  <ul className="myroom_nav_iconList">
                    <Link to="/avatar">
                      <li className="iconListItem">
                        <div className="icon">
                          <img
                            src={Myroom_menu_icon06}
                            alt="アバターのお着替え"
                          />
                        </div>
                        <p className="caption">着替える</p>
                      </li>
                    </Link>
                    <Link to="/">
                      <li className="iconListItem">
                        <div className="icon">
                          <img
                            src={Myroom_menu_icon07}
                            alt="マイタウンへお出かけ"
                          />
                        </div>
                        <p className="caption">マイタウンへお出かけ</p>
                      </li>
                    </Link>
                  </ul>
                </div>
              </div>
              <a
                className="__NavArea_policyLink"
                href="https://www.meijiyasuda.co.jp/privacypolicy/index.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                個人情報の保護に関する方針
              </a>
            </nav>
          ) : (
            <nav className="__NavArea">
              <ul className="__NavArea_nav">
                <li>
                  <Link to="/myroom">
                    <i className="IconArrow" />
                    <span>
                      <img src={Menu_myroom} alt="マイルーム" />
                    </span>
                  </Link>
                </li>
                <li>
                  <Link to="/">
                    <i className="IconArrow" />
                    <span>
                      <img src={Menu_towntop} alt="タウントップ" />
                    </span>
                  </Link>
                </li>
                <li>
                  <Link to="/park">
                    <i className="IconArrow" />
                    <span>
                      <img src={Menu_park} alt="健活パーク" />
                    </span>
                  </Link>
                </li>
                <li>
                  <Link to="/school">
                    <i className="IconArrow" />
                    <span>
                      <img src={Menu_school} alt="ラーニングスクール" />
                    </span>
                  </Link>
                </li>
                <li>
                  <Link to="/cafe">
                    <i className="IconArrow" />
                    <span>
                      <img src={Menu_cafe} alt="スタイルカフェ" />
                    </span>
                  </Link>
                </li>
                <li>
                  <Link to="/house">
                    <i className="IconArrow" />
                    <span>
                      <img src={Menu_apartment} alt="ライフメゾン" />
                    </span>
                  </Link>
                </li>
              </ul>
              <div className="__NavArea_news">
                <button onClick={_ => history.push('/news')}>
                  <img src={Menu_topictnews} alt="トピックス & ニュース" />
                </button>
              </div>
              <a
                className="__NavArea_policyLink"
                href="https://www.meijiyasuda.co.jp/privacypolicy/index.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                個人情報の保護に関する方針
              </a>
            </nav>
          )}

          <aside className="__BannerArea">
            <div className="__BannerArea_avatar">
              <button onClick={_ => history.push('/avatar')}>
                <img src={Btn_Avatar} alt="キャラクター編集" />
              </button>
            </div>
            <ul className="__BannerArea_btn">
              {isLogin ? (
                <li className="__logout">
                  <button
                    onClick={() => {
                      onLogout()
                      onClose()
                    }}
                  >
                    <img src={Menu_Logout} alt="ログアウト" />
                  </button>
                </li>
              ) : (
                <>
                  <li className="__login">
                    <button
                      onClick={() => {
                        onLogin()
                        onClose()
                      }}
                    >
                      <img src={Menu_Login} alt="ログイン" />
                    </button>
                  </li>
                  {/* <li className="__signup">
                    <button
                      onClick={() => {
                        onLogin()
                        onClose()
                      }}
                    >
                      <img src={Menu_Signup} alt="キャラクターを作って遊ぶ" />
                    </button>
                  </li> */}
                </>
              )}
              {isLogin && (
                <li className="__disconnect">
                  <button
                    onClick={() => {
                      onDisconnect()
                      onClose()
                    }}
                  >
                    <img src={Btn_Disconnect} alt="保存データを削除" />
                  </button>
                </li>
              )}
            </ul>

            <ShareButtons
              twitter={twitterShareURL}
              facebook={facebookShareURL}
              line={lineShareURL}
            />

            <ul className="__BannerArea_banner">
              {/* <li>
                <a
                  href="https://www.meijiyasuda.co.jp/enjoy/ld/ladies/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="../img/Bnr_01.png" alt="" />
                </a>
              </li> */}
              <li>
                <a
                  href="https://line.naver.jp/ti/p/%40meijiyasudalife"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="../img/Bnr_02.png" alt="" />
                </a>
              </li>
            </ul>
          </aside>
        </div>
      </animated.div>
      <ButtonCloseX onClose={onClose} />
    </animated.div>
  )
}

MenuBox.propTypes = {
  isLogin: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onLogin: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
  onDisconnect: PropTypes.func.isRequired,
  style: PropTypes.object.isRequired,
}

export default MenuBox
