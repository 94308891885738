import * as PageName from './PageName'

const ITEMS = new Map([
  [
    PageName.TOWN,
    [
      // {
      //   type: 'animation',
      //   name: 'town_1-1/town_1-1',
      //   length: 26,
      //   animationSpeed: 0.25,
      //   position: { x: 444, y: 662 },
      //   startDelay: 0,
      //   loopDelay: 0,
      //   reverse: false,
      // },
      {
        type: 'animation',
        name: 'town_1-2/town_1-2',
        length: 26,
        animationSpeed: 0.25,
        position: { x: 2296, y: 581 },
        startDelay: 0,
        loopDelay: 0,
        reverse: false,
      },
      {
        type: 'animation',
        name: 'town_4/town_4',
        length: 16,
        animationSpeed: 0.25,
        position: { x: 1494, y: 1542 },
        startDelay: 0,
        loopDelay: 0,
        reverse: false,
        move: {
          animationLink: false,
          positions: [
            {
              pos: { x: 1494, y: 1482 },
              duration: 2,
              ease: 'power1.inOut',
            },
            {
              pos: { x: 1494, y: 1542 },
              duration: 2,
              ease: 'power1.inOut',
            },
          ],
        },
      },
      {
        type: 'animation',
        name: 'town_5/town_5',
        length: 21,
        animationSpeed: 0.25,
        position: { x: 1420, y: 1195 },
        startDelay: 0,
        loopDelay: 0,
        reverse: false,
      },
      {
        type: 'animation',
        name: 'town_7/town_7',
        length: 36,
        animationSpeed: 0.25,
        position: { x: 639, y: 1255 },
        startDelay: 0,
        loopDelay: 0,
        reverse: false,
      },
      {
        type: 'animation',
        name: 'town_8/town_8',
        length: 35,
        animationSpeed: 0.25,
        position: { x: 913, y: 1602 },
        startDelay: 0,
        loopDelay: 0,
        reverse: false,
      },
      {
        type: 'animation',
        name: 'town_11/town_11',
        length: 33,
        animationSpeed: 0.25,
        position: { x: 1750, y: 1550 },
        startDelay: 0,
        loopDelay: 0,
        reverse: false,
      },
      {
        type: 'animation',
        name: 'town_12/town_12',
        length: 43,
        animationSpeed: 0.25,
        position: { x: 1800, y: 737 },
        startDelay: 0,
        loopDelay: 0,
        reverse: false,
      },
      {
        type: 'animation',
        name: 'town_13/town_13',
        length: 34,
        animationSpeed: 0.25,
        position: { x: 1590, y: 1380 },
        startDelay: 0,
        loopDelay: 0,
        reverse: false,
      },
    ],
  ],
  [
    PageName.PARK,
    [
      {
        type: 'animation',
        name: 'park_1/park_1',
        length: 46,
        animationSpeed: 0.25,
        position: { x: 1664, y: 1153 },
        startDelay: 1,
        loopDelay: 1,
        reverse: false,
        move: {
          animationLink: true,
          positions: [
            {
              pos: { x: 1614, y: 1077 },
              duration: 3,
              ease: 'none',
            },
            {
              pos: { x: 1664, y: 1153 },
              duration: 3,
              ease: 'none',
            },
          ],
        },
      },
      {
        type: 'animation',
        name: 'park_2/park_2',
        length: 43,
        animationSpeed: 0.25,
        position: { x: 950, y: 753 },
        startDelay: 0,
        loopDelay: 0,
        reverse: false,
      },
      {
        type: 'animation',
        name: 'park_3_left/park_3_left',
        length: 9,
        animationSpeed: 0.25,
        position: { x: 550, y: 1124 },
        startDelay: 0,
        loopDelay: 0,
        reverse: false,
        move: {
          animationLink: false,
          positions: [
            {
              pos: { x: 550, y: 1028 },
              duration: 2,
              ease: 'power1.inOut',
            },
            {
              pos: { x: 550, y: 1124 },
              duration: 2,
              ease: 'power1.inOut',
            },
          ],
        },
      },
      {
        type: 'animation',
        name: 'park_3_right/park_3_right',
        length: 9,
        animationSpeed: 0.25,
        position: { x: 1822, y: 816 },
        startDelay: 0,
        loopDelay: 0,
        reverse: false,
        move: {
          animationLink: false,
          positions: [
            {
              pos: { x: 1822, y: 1008 },
              duration: 4,
              ease: 'power1.inOut',
            },
            {
              pos: { x: 1822, y: 816 },
              duration: 4,
              ease: 'power1.inOut',
            },
          ],
        },
      },
      {
        type: 'animation',
        name: 'park_4/park_4',
        length: 15,
        animationSpeed: 0.25,
        position: { x: 2184, y: 599 },
        startDelay: 0,
        loopDelay: 0,
        reverse: false,
      },
      {
        type: 'animation',
        name: 'park_5/park_5',
        length: 38,
        animationSpeed: 0.25,
        position: { x: 1590, y: 1592 },
        startDelay: 0,
        loopDelay: 0,
        reverse: false,
      },
      {
        type: 'animation',
        name: 'park_6/park_6',
        length: 33,
        animationSpeed: 0.25,
        position: { x: 534, y: 1192 },
        startDelay: 0,
        loopDelay: 0,
        reverse: false,
      },
    ],
  ],
  [
    PageName.CAFE,
    [
      {
        type: 'animation',
        name: 'cafe_1/cafe_1',
        length: 36,
        animationSpeed: 0.25,
        position: { x: 864, y: 308 },
        startDelay: 0,
        loopDelay: 1,
        reverse: false,
      },
      {
        type: 'animation',
        name: 'cafe_2/cafe_2',
        length: 38,
        animationSpeed: 0.25,
        position: { x: 758, y: 1005 },
        startDelay: 0,
        loopDelay: 0,
        reverse: false,
      },
      {
        type: 'animation',
        name: 'cafe_3/cafe_3',
        length: 44,
        animationSpeed: 0.25,
        position: { x: 1180, y: 1243 },
        startDelay: 0,
        loopDelay: 0,
        reverse: false,
      },
      {
        type: 'animation',
        name: 'cafe_4/cafe_4',
        length: 25,
        animationSpeed: 0.25,
        position: { x: 1694, y: 1432 },
        startDelay: 0,
        loopDelay: 1,
        reverse: false,
      },
      {
        type: 'animation',
        name: 'cafe_5/cafe_5',
        length: 37,
        animationSpeed: 0.25,
        position: { x: 1811, y: 712 },
        startDelay: 1,
        loopDelay: 1,
        reverse: true,
      },
      {
        type: 'animation',
        name: 'cafe_6/cafe_6',
        length: 27,
        animationSpeed: 0.25,
        position: { x: 1980, y: 990 },
        startDelay: 0,
        loopDelay: 1,
        reverse: false,
      },
    ],
  ],
  [
    PageName.SCHOOL,
    [
      {
        type: 'animation',
        name: 'school_1/school_1',
        length: 21,
        animationSpeed: 0.25,
        position: { x: 1097, y: 1027 },
        startDelay: 0,
        loopDelay: 0,
        reverse: false,
      },
      {
        type: 'animation',
        name: 'school_2/school_2',
        length: 31,
        animationSpeed: 0.25,
        position: { x: 999, y: 1396 },
        startDelay: 0,
        loopDelay: 1.0,
        reverse: false,
      },
      {
        type: 'animation',
        name: 'school_3/school_3',
        length: 31,
        animationSpeed: 0.25,
        position: { x: 1370, y: 1247 },
        startDelay: 0,
        loopDelay: 0,
        reverse: false,
      },
      {
        type: 'animation',
        name: 'school_4/school_4',
        length: 33,
        animationSpeed: 0.25,
        position: { x: 344, y: 1013 },
        startDelay: 0,
        loopDelay: 0,
        reverse: false,
      },
      {
        type: 'animation',
        name: 'school_5/school_5',
        length: 27,
        animationSpeed: 0.25,
        position: { x: 1254, y: 483 },
        startDelay: 0,
        loopDelay: 1.0,
        reverse: false,
      },
    ],
  ],
  [PageName.BANK, []],
  [
    PageName.HOUSE,
    [
      {
        type: 'animation',
        name: 'house_1/house_1',
        length: 35,
        animationSpeed: 0.25,
        position: { x: 1133, y: 1246 },
        startDelay: 0,
        loopDelay: 1,
        reverse: false,
      },
      {
        type: 'animation',
        name: 'house_2/house_2',
        length: 26,
        animationSpeed: 0.25,
        position: { x: 827, y: 1086 },
        startDelay: 0,
        loopDelay: 1,
        reverse: false,
      },
      {
        type: 'animation',
        name: 'house_3/house_3',
        length: 43,
        animationSpeed: 0.25,
        position: { x: 1339, y: 809 },
        startDelay: 1,
        loopDelay: 1,
        reverse: false,
      },
      {
        type: 'animation',
        name: 'house_4/house_4',
        length: 84,
        animationSpeed: 0.25,
        position: { x: 1889, y: 1411 },
        startDelay: 0,
        loopDelay: 1,
        reverse: false,
      },
      {
        type: 'animation',
        name: 'house_5/house_5',
        length: 27,
        animationSpeed: 0.25,
        position: { x: 1250, y: 1140 },
        startDelay: 0,
        loopDelay: 1,
        reverse: false,
      },
    ],
  ],
])

export default ITEMS
