import React from 'react'
import PropTypes from 'prop-types'

import Logo from '../img/common/Footer_logo.png'

const Copyright = ({ pathname }) => {
  const pathArray = ['/myroom', '/myroom/main', '/consult']
  const boo = pathArray.includes(pathname)
  return (
    <div className="Copyright">
      {/* <p className="textNum">{boo ? '募Ⅱ2001075営企' : ''}</p> */}
      <div className="Copyright-wrapper">
        <b>
          <a
            href="https://www.meijiyasuda.co.jp/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={Logo} alt="明治安田" />
          </a>
        </b>
        <small>
          Copyright Meiji Yasuda Life Insurance Company All Rights Reserved.
        </small>
      </div>
    </div>
  )
}

Copyright.propTypes = {
  // style: PropTypes.object.isRequired,
}

export default Copyright
