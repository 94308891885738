import React from 'react'
import PropTypes from 'prop-types'
import { animated } from 'react-spring'

import ModalTitle from '../../img/modal/Modal_title__guide.png'
import useModalIntro from '../../hooks/useModalIntro'

const Guide = ({ children, image }) => {
  const modalStyle = useModalIntro()

  return (
    <section className="ModalGuide">
      <animated.div style={modalStyle} className="_Modal -type_1">
        <div className="__wrap">
          <h1 className="__title">
            <img src={ModalTitle} alt="画面説明" />
          </h1>

          <div className="ModalGuide_img">{image()}</div>
        </div>
      </animated.div>
      {children}
    </section>
  )
}

Guide.propTypes = {
  children: PropTypes.node.isRequired,
  image: PropTypes.func.isRequired,
}

export default Guide
