import React from 'react'

import BtnConsult from '../../img/ui/Btn_Consult.png'

const Consult = ({ pathname }) => {
  return (
    <div className={pathname === '/myroom/main' ? 'Consult myroom' : 'Consult'}>
      <a
        href="/consult/"
        className="Consult_btn"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={BtnConsult} alt="" />
      </a>
    </div>
  )
}

export default Consult
